<template>
  <article class="art">
    <div class="art-section standard container md">
      <div class="art-title">
        <h2 class="art-title__main">Rejuvenated Human Capital</h2>
        <h3 class="art-title__sub">
          What’s the secret to building an unbreakable bond with customers?
        </h3>
      </div>
      <p>
        Trust is built through human connection. Just think about it. What details do you share with an expert seller in
        a face-to-face encounter when you believe that person is trying to help you with a problem or opportunity?
        Compare that to what you would share with an anonymous call center representative. Compare that to what you
        would share with a chatbot. Human connection unlocks the human condition.
      </p>
      <p>
        Hyper-personal relationships between brand ambassadors and customers foster trust, empathy, care, and
        anticipation. Within this framework, approved brand ambassadors (influencers, advocates, personal shoppers,
        store clerks, sales reps, call center) are paired with specific customers to deliver services. Customers feel
        understood, appreciated and respected. Brand Ambassadors are compensated for long-tailed performance. Brands
        improve retention, loyalty, and advocacy.
      </p>
    </div>

    <div class="art-sep">
      <img src="@/assets/img/retail/crowd.jpg" alt=""/>
    </div>

    <div class="art-section column container md">
      <div class="art-title">
        <h3 class="art-title__main">Weak personalization</h3>
      </div>
      <div class="content">
        <p>
          Today, marketers use five techniques to personalize CX: email, SMS, retargeting, site personalization, and
          loyalty apps. The first three are educated guesses (at best). The fourth is a recommendation engine, which
          itself is an educated guess dictated solely by the conversion-centric legacy. The fifth, loyalty apps, have
          become little more than “offer machines” serving up more educated guesses. While striving to feel more
          personal, all five usually say, “We don’t really know you.”
        </p>
        <p>
          Umego flips that paradigm. It provides evergreen opportunities for expert sellers to engage customers
          anywhere, anytime, for any reason. Every interaction is live, with the personality of face-to-face engagement.
          It’s personal. It’s meaningful. It enables relationships to blossom. Intelligence gathered helps determine
          propensity to purchase, propensity to stay, investment level worthy of that relationship, and strategies for
          new customer acquisition. Umego is where “old-world values meet new-world capabilities”, with personalized
          experiences that drive advanced CLV forecasting for individual customers and aggregate intelligence for
          overall business growth.
        </p>
      </div>
    </div>

    <div class="art-sep">
      <img src="@/assets/img/retail/old-men.jpg" alt=""/>
    </div>

    <div class="art-section column container md">
      <div class="art-title">
        <h3 class="art-title__main">Empowered Human Capital</h3>
      </div>
      <div class="content">
        <p>
          Umego also represents a sales training renaissance. Once a brand ambassador is paired with a customer, they
          are taught how to use the intelligence gathered to care for, listen to, nurture, serve, and respect the
          customer. They’re encouraged to take a long-tailed, customer-centric approach to selling. They’re incentivized
          to advocate for the brand and the customer’s success. Our team at Umego has twenty years of experience in
          sales enablement, leveraging our proprietary platform (UmeU) to work across sales teams, including
          wholesalers, retailers, bartenders, servers, floor clerks, influencers, SMEs, and brand advocates.
        </p>
        <p>
          Umego also represents a sales training renaissance. Once a brand ambassador is paired with a customer, they
          are taught how to use the intelligence gathered to care for, listen to, nurture, serve, and respect the
          customer. They’re encouraged to take a long-tailed, customer-centric approach to selling. They’re incentivized
          to advocate for the brand and the customer’s success. Our team at Umego has twenty years of experience in
          sales enablement, leveraging our proprietary platform (UmeU) to work across sales teams, including
          wholesalers, retailers, bartenders, servers, floor clerks, influencers, SMEs, and brand advocates.
        </p>
      </div>
    </div>
  </article>
</template>

<script>
export default {
  name: 'RejuvenatedHumanCapital',
}
</script>
<style scoped lang="less">
@import (reference) "src/assets/css/style";

</style>
