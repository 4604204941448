<template>
  <article class="art">
    <div>
      <img src="@/assets/img/industries/ModernizingTraditions.png"/>
    </div>
    <div class="column container md ">
      <div class="content">
        <div class="content_title">Old-world values, new-world capabilities</div>
        <div class="content_text">
          <p>Umego is also for brands whose customers are invested in category knowledge delivered through expert
            sellers. These customers are curious, engaged, and invested in outcomes that improve their lives. They value
            familiarity, consistency, shared knowledge, kinship, personal touch, and the sincere belief that the brand
            actually cares for them, personally and individually. Umego aligns these “old-world values with new-world
            capabilities" by fusing basic attributes of compatibility and sustainability with the digital economy
            prerequisites of scalability and repeatability. </p>
          <p>Everyone wants “a guy” or “a gal” whom they trust at any business that wants their loyalty. They want their
            lives and lifestyles to be served in customer-centric ways. The digital economy tells brands “who purchased
            what”, and “when, where, and how it happened” but it has never told brands “why it happens.” Our
            proprietary, brand-specific AI-powered LLMs answer “why” and enable brands to respond in customer-centric
            ways. This paradigm shift helps brands better understand which customers should receive which level of
            service, unearthing the most important customers today and the ones with the highest likelihood of being the
            most important customers tomorrow and beyond.</p>
          <p>Some segments of your customers will want to be served by high-touch, concierge-level brand ambassadors.
            Other segments will be happy to be served by simulated, persona-based synthetic brand ambassadors. Either
            way, zero-party human intelligence delivered through our customer intelligence platform will dramatically
            enhance the compatibility and sustainability of your brand while offering repeatable and scalable revenue
            growth. </p>
        </div>

        <div class="content_title">Umego promises brands</div>
          <div class="flex-row">
            <ul>
              <li>Customer centricity</li>
              <li>Emotional intelligence</li>
              <li>Coaching & mentorship</li>
              <li>Higher NPS</li>
              <li>Higher CLV</li>
            </ul>
            <ul>
              <li>More trust, empathy, & care</li>
              <li>Strategic alignment</li>
              <li>Systems management</li>
              <li>The art of anticipation</li>
            </ul>
          </div>
      </div>
    </div>
  </article>
</template>

<script>
export default {
  name: 'ModernizingTraditions',
}
</script>
<style scoped lang="less">
@import (reference) "src/assets/css/style";

.container{
  margin: unset;
}

.art {
  display: flex;
  gap: @gap*0;

  .art-title {
    display: flex;
    flex-direction: column;
    gap: @gap*2;
    font-size: 28px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;

    &__main {
      font-size: 54px;
      font-style: normal;
      font-weight: 700;
      line-height: 120%;
      color: #F3F2F7;
    }
  }

  img {
    height: calc(100vh - @headerHeight);
  }

  ul {
    padding-left: 15px;
    flex-grow: 1;
  }

  .content {
    padding-top: 0;
    align-items: stretch;
  }

  @media (max-width: @screen-tablet) {
    gap: @gap*3;
    .art-title {
      font-size: 20px;

      &__main {
        font-size: 42px;
      }
    }

    //flex-direction: column;
    img {
      width: 100%;
      height: auto;
    }
  }
  @media (max-width: @screen-mobile) {
    gap: @gap*2;
    .art-title {
      font-size: 16px;

      &__main {
        font-size: 36px;
      }
    }
  }
}
</style>
