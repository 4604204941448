<template>
  <div class="menu">
    <button class="toggle" @click="toggleMenu()">
      <span v-if="!showMenu">
        <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" fill="none">
          <path d="M5.5 8.125C5.5 7.50156 6.00156 7 6.625 7H25.375C25.9984 7 26.5 7.50156 26.5 8.125C26.5 8.74844 25.9984 9.25 25.375 9.25H6.625C6.00156 9.25 5.5 8.74844 5.5 8.125ZM5.5 15.625C5.5 15.0016 6.00156 14.5 6.625 14.5H25.375C25.9984 14.5 26.5 15.0016 26.5 15.625C26.5 16.2484 25.9984 16.75 25.375 16.75H6.625C6.00156 16.75 5.5 16.2484 5.5 15.625ZM26.5 23.125C26.5 23.7484 25.9984 24.25 25.375 24.25H6.625C6.00156 24.25 5.5 23.7484 5.5 23.125C5.5 22.5016 6.00156 22 6.625 22H25.375C25.9984 22 26.5 22.5016 26.5 23.125Z" fill="#F1F1F2"/>
        </svg>
      </span>
      <span v-if="showMenu">
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
          <path d="M4.35156 3.52246C3.98438 3.15918 3.39062 3.15918 3.02344 3.52246C2.65625 3.88574 2.66016 4.4834 3.02344 4.85059L10.1719 11.999L3.02344 19.1475C2.65625 19.5146 2.65625 20.1084 3.02344 20.4717C3.39062 20.835 3.98438 20.8389 4.34766 20.4717L11.4961 13.3232L18.6484 20.4756C19.0156 20.8428 19.6094 20.8428 19.9727 20.4756C20.3359 20.1084 20.3398 19.5146 19.9727 19.1514L12.8242 12.0029L19.9766 4.85059C20.3438 4.4834 20.3438 3.88965 19.9766 3.52637C19.6094 3.16309 19.0156 3.15918 18.6523 3.52637L11.5039 10.6748L4.35156 3.52246Z" fill="#E2E2E3"/>
        </svg>
      </span>
    </button>
    <div class="wrap" :class="{'in': showMenu}">
      <div class="menu-header">
<!--        <button class="btn-back" @click="toggleMenu()">
          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
            <path d="M3.28906 12.6795C3.10547 12.4998 3 12.2576 3 11.9998C3 11.742 3.10547 11.4998 3.28906 11.3201L10.1641 4.7576C10.5391 4.39823 11.1328 4.41385 11.4883 4.78885C11.8438 5.16385 11.832 5.7576 11.457 6.11307L6.27734 11.0623H19.5625C20.082 11.0623 20.5 11.4803 20.5 11.9998C20.5 12.5193 20.082 12.9373 19.5625 12.9373H6.27734L11.4609 17.8826C11.8359 18.242 11.8477 18.8318 11.4922 19.2068C11.1367 19.5818 10.543 19.5935 10.168 19.2381L3.29297 12.6756L3.28906 12.6795Z" fill="#E2E2E3"/>
          </svg>
        </button>
        <div class="title">Title</div>-->
      </div>
      <nav class="list">
        <div class="list__item"
             v-for="(item, index) in navItems" :key="index">
          <div v-if="item.submenu" @click="toggleSubmenu" class="list__item_link">
            {{ item.name }}
            <div class="arrow" :class="{'rotate': showSubmenu}">
              <svg xmlns="http://www.w3.org/2000/svg" width="14" height="8" viewBox="0 0 14 8" fill="none">
              <path d="M6.55044 7.31445C6.7971 7.55664 7.2029 7.55664 7.44956 7.31445L13.815 1.06445C14.0617 0.822266 14.0617 0.423828 13.815 0.181641C13.5683 -0.0605469 13.1625 -0.0605469 12.9159 0.181641L7 5.99023L1.08411 0.181641C0.837454 -0.0605469 0.431657 -0.0605469 0.184996 0.181641C-0.0616653 0.423828 -0.0616653 0.822266 0.184996 1.06445L6.55044 7.31445Z" fill="#F3F2F7"/>
            </svg>
            </div>
          </div>
          <router-link v-else class="list__item_link" :to="item.path" @click="navClick">
            {{ item.name }}
          </router-link>

          <div class="submenu" v-if="item.submenu" :class="{'in': showSubmenu}">
            <div class="list__item" @click="navClick"
                 v-for="(subitem, index) in item.submenu" :key="index">
              <router-link
                  class="list__item_link"
                  :to="subitem.path">
                {{ subitem.name }}
              </router-link>
            </div>
          </div>
        </div>

      </nav>
    </div>
  </div>
</template>

<script>

export default {
  name: 'Menu',
  props: {
    navItems: {
      type: Array
    }
  },
  data: function () {
    return {
      showMenu: false,
      showSubmenu: false,
    }
  },
  methods: {
    toggleMenu: function () {
      this.showMenu = !this.showMenu;
    },
    toggleSubmenu: function () {
      this.showSubmenu = !this.showSubmenu;
    },
    navClick: function () {
      console.log('CLICK');
      this.showMenu = false;
    },
    handle1NavClick: function () {
      console.log('CLICK');
      this.showMenu = false;
    }
  }
}
</script>

<style scoped lang="less">
@import (reference) "src/assets/css/style";

.menu {
  display: flex;
  align-items: center;
  height: 100%;
  font: 16px @fontFamily;
  cursor: pointer;

  @media(max-width: @screen-tablet) {
    font: 500 14px/125% @fontFamily;
  }
}

.toggle {
  display: none;
  position: relative;
  cursor: pointer;
  font-size: 0;
  padding: 8px;
  background: transparent;
  border: none;

  svg {
    fill: #fff;
  }
  @media(max-width: @screen-tablet) {
    display: block;
    flex-shrink: 0;
    z-index: 8;
  }
}

.wrap {
  height: inherit;

  @media(max-width: @screen-tablet) {
    position: absolute;
    z-index: 7;
    top: 0;
    right: 0;
    width: 320px;
    max-width: 0;
    overflow: hidden;
    height: auto;
    padding-bottom: 16px;
    border-radius: 16px 0 0 16px;
    background: @colorBgDark;
    .animate(@duration*1s);

    &.in {
      max-width: 100%;
    }
  }
  @media(max-width: @screen-mobile) {
    width: 100%;
    height: 100vh;
    border-radius: 0;
  }
}

.arrow {
  .animate(@duration*2s);
  &.rotate {
    transform: rotate(180deg);
  }
}

.list {
  display: flex;
  height: inherit;
  gap: 16px;

  &__item {
    display: inline-flex;
    height: 100%;
    align-items: center;

    &_link {
      display: inline-flex;
      width: 100%;
      padding: 0 16px;
      color: #F1F1F2;
      text-decoration: none;
      justify-content: space-between;
      align-items: center;
      gap: @gap*0.5;
    }
    &.active {
      box-shadow: inset 0 0 1px 1px rgba(248, 248, 248, 0.08);
      background: #F8F8F833;
    }
    &:hover {
      opacity: 1;
    }
  }

  @media(max-width: @screen-tablet) {
    flex-direction: column;
    gap: 0;

    &__item {
      width: 100%;
      flex-direction: column;
      height: auto;

      &_link {
        width: 100%;
        padding: 12px;
        border-bottom: 1px solid #F8F8F833;
      }
    }
  }
}

.submenu {
  display: flex;
  flex-direction: column;
  width: 100%;
  max-height: 0;
  overflow: hidden;
  .animate(@duration*2s);

  .list__item {
    width: 100%;
    font-size: 14px;
    color: #ABABAE;

    &_link {
      padding-left: @gap;
      color: inherit;
    }
  }
  @media(max-width: @screen-tablet) {
    &.in {
      max-height: 500px;
    }
  }
}

@media(min-width: (@screen-tablet + 1)) {
  .list {
    &__item {

      &_link {

      }
    }
  }

  .submenu {
    background: red;
    position: absolute;
    top: 100%;
    right: 0;
  }

  .list__item:hover {
    .submenu {
      max-height: 300px;
    }
  }
}

.menu-header {
  display: none;
  height: @headerHeightPhone;
  padding: 8px 4px;
  align-items: center;

  .btn-back {
    display: flex;
    width: 48px;
    height: 48px;
    justify-content: center;
    align-items: center;
    background: transparent;
    border: none;
  }
  .title {

  }

  @media(max-width: @screen-tablet) {
    display: flex;
  }
}


</style>
