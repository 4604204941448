<template>
  <article class="art platform">
    <div class="art-section standard container md">
      <div class="flex-column">
        <div class="art-title">
          <h3 class="art-title__main">{re}humanize your brand relationships.</h3>
        </div>
        <div class="animate-wrapper">
          <div class="animate">
            <ul class="list">
              <li>Deep learning insights</li>
              <li>Emotional intelligence</li>
              <li>Proactive engagement</li>
              <li>Unlocked opportunity</li>
              <li>Continuous learning</li>
              <li>Perpetual feedback</li>
              <li>Institutional scalability</li>
              <li>Privacy and trust</li>
              <li>Cross-channel integration</li>
            </ul>
            <ul class="list absolute">
              <li>Deep learning insights</li>
              <li>Emotional intelligence</li>
              <li>Proactive engagement</li>
              <li>Unlocked opportunity</li>
              <li>Continuous learning</li>
              <li>Perpetual feedback</li>
              <li>Institutional scalability</li>
              <li>Privacy and trust</li>
              <li>Cross-channel integration</li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </article>
</template>

<script>
export default {
  name: 'Section8'
}
</script>

<style scoped lang="less">
@import (reference) "src/assets/css/style";

.flex-column {
  align-items: center;
}
.animate-wrapper{
  mask-image: linear-gradient(to right, rgba(0, 0, 0, 0) 0%, rgb(0, 0, 0) 12.5%, rgb(0, 0, 0) 87.5%, rgba(0, 0, 0, 0) 100%);
  overflow: hidden;
  width: 80%;
  .animate {
    width: max-content;
    position: relative;
    .animation(textScroll 20s linear infinite running);

    .list {
      display: flex;
      flex-direction: row;
      gap: @gap;
      align-items: center;
      white-space: nowrap;
      padding-left: @gap;
      li{
        padding: 10px;
      }
    }

    .absolute {
      position: absolute;
      top: 0;
      left: 100%;
      height: 100%;
    }
  }
}



</style>
