<template>
  <article class="art platform">
    <div class="art-section standard container md">
      <div class="flex-column">
        <div class="flex-row">
          <div class="art-title">
            <h3 class="art-title__main">Relationships matter.</h3>
          </div>
          <p>
            Our AI-driven customer intelligence platform unlocks and unleashes predictive brand experiences that drive
            revenue at scale. We activate hyperpersonal relationships between brands and their most important customers by
            uniquely fusing immersive livestream e-commerce experiences with powerful behavioral and social science-based
            DLMs. We deliver trust, empathy, and care because relationships matter.
          </p>
        </div>
      </div>
    </div>
  </article>
<!--  <section class="section2">
    <div class="container md">
      <div class="art-section standard container md">
        <div class="flex-column">
          <div class="flex-row">
            <div class="art-title">
              <h3 class="art-title__main">Relationships matter.</h3>
            </div>
              <p>
                Our AI-driven customer intelligence platform unlocks and unleashes predictive brand experiences that drive
                revenue at scale. We activate hyperpersonal relationships between brands and their most important customers by
                uniquely fusing immersive livestream e-commerce experiences with powerful behavioral and social science-based
                DLMs. We deliver trust, empathy, and care because relationships matter.
              </p>
          </div>
        </div>
      </div>
      <div class="title">
        <h3>Relationships matter.</h3>
      </div>
      <p>
        Our AI-driven customer intelligence platform unlocks and unleashes predictive brand experiences that drive
        revenue at scale. We activate hyperpersonal relationships between brands and their most important customers by
        uniquely fusing immersive livestream e-commerce experiences with powerful behavioral and social science-based
        DLMs. We deliver trust, empathy, and care because relationships matter.
      </p>
    </div>
  </section>-->
</template>

<script>
export default {
  name: 'Section2',
}
</script>

<style scoped lang="less">
@import (reference) "src/assets/css/style";

.section2 {
  padding: @gap*4 0 @gap*2;
  font: 29px/150% @fontFamily;
  color: @colorNeutralLight;
  @media (max-width: @screen-tablet) {
    font: 24px/150% @fontFamily;
  }
  @media (max-width: @screen-mobile_sm) {
    font: 16px/150% @fontFamily;
  }
}

.title {
  font: 300 52px/120% @fontFamily;
  margin-bottom: @gap*1.5;

  &:after {
    content: '';
    display: block;
    height: 4px;
    margin-top: @gap*0.66;
    background: linear-gradient(90deg, #3F2AB0 0%, #281885 50%, #0C053D 100%);
  }

  @media (max-width: @screen-tablet) {
    font: 300 42px/120% @fontFamily;
  }
  @media (max-width: @screen-mobile_sm) {
    font: 300 36px/120% @fontFamily;
  }
}

</style>
