<template>
  <article class="art platform">
    <div class="art-section standard container md">
      <div class="flex-column">
        <div class="art-title">
          <h3 class="art-title__main">Capture and activate zero-party customer intelligence</h3>
        </div>
        <div class="grid">
          <div class="col-1">
            <div class="item">
              <h3>Meet. Tap. Share. Excite.</h3>
              <p>Pair individual customers with their ideal brand ambassadors.</p>
              <img loading="lazy" src="@/assets/img/home/Section7/b8d8d6d43faba092f437f43a009877cc.png"/>
            </div>
          </div>
          <div>
            <div class="item">
              <h3>Listen. Learn. Connect. Support.</h3>
              <p>Ignite curious, available, and customer-centric conversations.</p>
              <img loading="lazy" src="@/assets/img/home/Section7/7954f46de85dec8b077260700c139135.png"/>
            </div>
          </div>
          <div>
            <div class="item">
              <h3>Trust. Empathize. Care. Anticipate.</h3>
              <p>Foster human relationships that are authentic and real.</p>
              <img loading="lazy" src="@/assets/img/home/Section7/a076ef8600d6cb3342159cb56e00f789.png"/>
            </div>
          </div>
          <div>
            <div class="item">
              <h3>Search. Browse. Shop. Sizzle.</h3>
              <p>Engage in immersive livestream video and chat commerce experiences.</p>
              <img loading="lazy" src="@/assets/img/home/Section7/7c7d2666f68b1ed5c99f6a3c2401baf9.png"/>
            </div>
          </div>
          <div>
            <div class="item">
              <h3>Influence. Inspire. Sponsor. Supercharge.</h3>
              <p>Activate enormous audience brand love.</p>
              <img loading="lazy" src="@/assets/img/home/Section7/6603841ce096b85d301bfd37469f2238.png"/>
            </div>
          </div>
        </div>
      </div>
    </div>
  </article>
</template>

<script>

export default {
  name: 'Section7',
}
</script>

<style scoped lang="less">
@import (reference) "src/assets/css/style";

.flex-column {
  align-items: center;
}

.grid {
  display: grid;
  width: 100%;
  //grid-template-columns: 1fr 1fr 1fr;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: repeat(2, 300px);
  gap: @gap;

  .col-1 {
    grid-row-start: 1;
    grid-row-end: 3;
  }
  @media (max-width: @screen-tablet) {
    grid-template-columns: repeat(1, 1fr);
    grid-template-rows: repeat(5, 400px);
    .col-1 {
      grid-row-start: 1;
      grid-row-end: 1;
    }
  }

  .item {
    display: flex;
    flex-direction: column;
    gap: (@gap/3);
    position: relative;
    text-align: center;
    overflow: hidden;
    height: 100%;
    border-radius: @gap @gap 0 0;
    padding-top: @gap*2;
    background: linear-gradient(0deg, #251C8A 0%, #0C053D 100%);
    opacity: 0;
    .animation(fadeIn 1s ease @duration*2s forwards);
    h3{
      font: 600 20px/125% @fontFamily;
      color: #F6F5FA;
    }
    p{
      font: 400 16px/150% @fontFamily;
      color: @colorTextLight;
    }
    img {
      width: 100%;
      padding: (@gap/3);
      border-radius: (@gap);
      position: absolute;
      top: 80%;
      opacity: .3;
      .animate(@duration*1s);

      &:hover {
        top: 100px;
        opacity: 1;
        //.animation(bounce 2s ease);
      }
      &:not(:hover) {
        .animation(bounce2 @duration*2s ease);
      }

    }
  }
}

</style>
