<template>
  <div class="article-nav">
    <div class="article-nav__section">
      <h4 class="article-nav__title">{{title}}</h4>
      <ul class="article-nav__list">
        <template v-for="(item, index) in articles" :key="index">
          <li :class="{ active: item.id==activeAnchor }"
              @click="navClick(item.id)">
            {{ item.title }}
          </li>
          <ul v-if="item.submenu" class="article-nav__sublist">
            <li v-for="(subitem, subindex) in item.submenu" :key="subindex"
                :class="{ active: subitem.id==activeAnchor }"
                @click="navClick(subitem.id)">{{ subitem.title }}</li>
          </ul>
        </template>
      </ul>
    </div>
    <div class="article-nav__section">
      <h4 class="article-nav__title">Connect</h4>
      <div class="connect">
        <p>
          Interesting in discovering what Umego can do for you?
        </p>
        <a href="https://umego.typeform.com/to/tt5sSG0Y" target="_blank" class="connect__link">
<!--          <svg xmlns="http://www.w3.org/2000/svg" width="48" height="48" viewBox="0 0 48 48" fill="none">
            <g clip-path="url(#clip0_616_4314)">
              <path d="M31.5 0C30.675 0 30 0.675 30 1.5C30 2.325 30.675 3 31.5 3H42.8812L19.9406 25.9406C19.3594 26.5219 19.3594 27.4781 19.9406 28.0594C20.5219 28.6406 21.4781 28.6406 22.0594 28.0594L45 5.11875V16.5C45 17.325 45.675 18 46.5 18C47.325 18 48 17.325 48 16.5V1.5C48 0.675 47.325 0 46.5 0H31.5ZM6 3C2.69063 3 0 5.69063 0 9V42C0 45.3094 2.69063 48 6 48H39C42.3094 48 45 45.3094 45 42V28.5C45 27.675 44.325 27 43.5 27C42.675 27 42 27.675 42 28.5V42C42 43.6594 40.6594 45 39 45H6C4.34062 45 3 43.6594 3 42V9C3 7.34062 4.34062 6 6 6H19.5C20.325 6 21 5.325 21 4.5C21 3.675 20.325 3 19.5 3H6Z" fill="#86B2BD"/>
            </g>
            <defs>
              <clipPath id="clip0_616_4314">
                <rect width="48" height="48" fill="white"/>
              </clipPath>
            </defs>
          </svg>-->
          Get in touch with us.
        </a>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ArticlesNav',
  props: {
    title: {
      type: String
    },
    articles: {
      type: Array
    },
  },
  data () {
    return {
      sectionObserver: null,
      sections : [],
      activeAnchor:null,
    }
  },
  mounted () {
    this.observeSections();
    this.$nextTick(() => {
      this.activeAnchor=this.articles[0]?.id || null;
    });
  },
  methods: {
    observeSections() {
      this.sections = document.querySelectorAll('article')
      try {
        this.sectionObserver.disconnect()
      } catch (error) {
        console;
      }

      const options = {
        rootMargin: '0px 0px',
        threshold: 0.3
      }
      this.sectionObserver = new IntersectionObserver(this.sectionObserverHandler, options)

      // Observe each section
      // const sections = document.querySelectorAll('article')
      this.sections.forEach(section => {
        this.sectionObserver.observe(section)
      })
    },
    sectionObserverHandler (entries) {
      for (const entry of entries) {
        if (entry.isIntersecting) {
          const sectionId = entry.target.id
          this.activeAnchor = sectionId;
          // Push sectionId to router here
          // this.$router.push({ name: this.$route.name, hash: `#${sectionId}` })
        }
      }
    },
    navClick: (id) => {
      var element = document.getElementById(id);
      // const yOffset = -100;
      // console.log(element.getBoundingClientRect().top);
      //
      // const y = element.getBoundingClientRect().top + yOffset;
      //
      // element.scrollTo({ top: y });

      element.scrollIntoView({behavior: 'smooth'});
    },
  },
}
</script>
<style scoped lang="less">
@import (reference) "../assets/css/style";

.article-nav {
  //float: left;
  width: @articleSidebarWidth;
  //margin: @gap*2 0 0 @gap;
  display: flex;
  flex-direction: column;
  gap: @gap;
  font: 400 16px/150% @fontFamily;
  color: @colorNeutralLight;
  height: calc(100vh - @headerHeight);
  background: var(--palette-light-brand-primary-primary-100, #180D66);
  position: sticky;
  top: 72px;
  flex-shrink: 0;


  &__section {
    padding: @gap;
    // background: @colorBgDark;
    // border: 1px solid rgba(248, 248, 248, 0.05);
    // border-radius: @gap;
    &:last-of-type {
      margin-top: auto;
    }
  }
  &__title {
    font: 600 23px/125% @fontFamily;
    letter-spacing: 0.184px;
    margin-bottom: @gap;
    //margin-bottom: 12px;
    padding-bottom: 12px;
    position: relative;
    &:after {
      content: '';
      position: absolute;
      left: 0;
      bottom: 0;
      width: 50%;
      height: 2px;
      background: linear-gradient(90deg, #0C053D 0%, #180D66 100%);
    }
  }
  &__list {
    display: flex;
    flex-direction: column;
    gap: @gap*0.33;

    li {
      position: relative;
      padding: @gap*0.33 0;
      cursor: pointer;
      /*&.active {
        color: red;
      }*/

      &:before {
        content: '';
        position: absolute;
        left: @gap*(-1);
        right: @gap*(-1);
        top: 0;
        height: 100%;
        background: #F8F8F833;
        opacity: 0;
      }

      &.active:before, &:hover:before {
        opacity: 1;
      }
    }
    .article-nav__sublist {
      list-style: disc;
      margin-left: @gap;
    }
  }

  @media(max-width: @screen-tablet) {
    display: none;
  }
  @media(max-width: @screen-mobile) {
    float: none;
    width: auto;
    margin: 0 -24px;

    &__section:first-child {
      display: none;
    }
  }
  @media(max-width: @screen-mobile_sm) {
    margin: 0 -16px;

    &__section {
      padding: 16px;
    }
  }
}

.connect {
  display: flex;
  flex-direction: column;
  gap: @gap*1.6;

  &__link {
    display: inline-block;
    font: inherit;
    color: inherit;
    text-decoration: none;
    text-align: center;
    border-radius: 12px;
    background: #685AD6;
    padding: 14px;

    svg {
      width: 20px;
      height: 20px;
      vertical-align: middle;
      margin-right: 5px;
    }
  }
}

</style>
