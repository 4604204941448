<template>
  <header class="header">
    <Logo :tagline=true />
    <Menu :nav-items="navItems" />
  </header>
</template>

<script>
import Logo from "@/components/Logo";
import Menu from "@/components/Menu";
import {NAV_LIST} from "@/router";

export default {
  name: 'HeaderComponent',
  components: {Menu, Logo},
  data: function () {
    return {
      navItems: NAV_LIST
    }
  },
}
</script>

<style scoped lang="less">
@import (reference) "src/assets/css/style";

.header {
  position: sticky;
  top: 0;
  z-index: 5;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: @headerHeight;
  padding: 0 @gap*2;
  background: @colorBgDark;
  background: linear-gradient(90deg, #281885 0%, #11093F 100%);
  color: #fff;

  @media(max-width: @screen-desktop) {
    padding: 0 @gap;
  }
  @media(max-width: @screen-tablet) {
    height: @headerHeightPhone;
  }
}
</style>
