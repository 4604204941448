<template>
  <article class="art">
    <div class="art-section standard container md">
      <div class="art-title">
        <h2 class="art-title__main">Disrupting the Digital Disruption</h2>
        <h3 class="art-title__sub">
          Navigating the digital labyrinth with unwavering customer-centricity and an insatiable curiosity.
        </h3>
      </div>
      <p>
        The “move fast and break things” technology culture assesses “what it could do” before “what it should do”. This
        creates a predator-prey dynamic designed to manipulate, persuade, prey upon, troll, and scrape consumers through
        cohort-led educated guesses driven by transactional and product-centric data. The ecosystem is obsessed with
        building a better mousetrap. Umego wants brands to think differently about the mouse.
      </p>
      <p>
        The current data models tell us “Who purchased what” and “when, where, and how” it happened. Then it
        deteriorates, first at the audience level, then cohorts, and finally for individuals. It becomes less and less
        accurate. Algorithms cannot code the human condition, so they prey upon its vulnerabilities. They do this not
        because it’s evil but because it works – to an extent.
      </p>
    </div>

    <div class="art-sep">
      <img src="@/assets/img/retail/purple-drops.jpg" alt=""/>
    </div>

    <div class="art-section column container md">
      <div class="art-title">
        <h3 class="art-title__main">Customer-centricity</h3>
      </div>
      <div class="content">
        <p>
          Our behavioral and social science strategy flips that dynamic, powering customer-centric algorithms through
          authentic human connection. We leverage tools and technology to shorten intimacy and relevancy time
          horizons.We collect and organize intelligence, learn, be curious, and make available 100% of the history
          between a company, brand ambassador hosts, and customers anytime, anywhere, to any stakeholder who needs it.
          Most importantly, we answer the critical question that has eluded brands since the start of the digital
          revolution - “Why consumers do what they do?”.
        </p>
        <p>
          There are too many human variables and too much nuance across audiences, cohorts, channels, income levels,
          journeys, and needs to understand the “why”. Learning why requires the art and insights of human interaction -
          trust, kinship, listening, caring, empathy, nurturing, and anticipation. By going beyond “first-party data” to
          “first-person experiences”, Umego unearths why individual customers do what they do and leverages the
          intelligence to develop more innovative and efficient customer-centric models. The value proposition is not to
          build a better mousetrap. It is to think differently about the mouse. In the end, our job is to serve the
          mouse better.
        </p>
      </div>
    </div>

    <div class="art-sep art-sep__phone art-sep__phone_right">
      <div class="container md">
        <img src="@/assets/img/retail/device_2.png" class="art-sep__device" alt=""/>
      </div>
      <img src="@/assets/img/retail/city-green.png" alt=""/>
    </div>
  </article>
</template>

<script>
export default {
  name: 'DisruptingDigitalDisruption',
}
</script>
<style scoped lang="less">
@import (reference) "src/assets/css/style";

</style>
