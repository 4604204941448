<template>
  <div class="intro" :class="{'in': !loading}" ref="target">
    <div class="intro__video">
      <video id="bg-video" muted loop preload="auto" playsinline ref="videoIntro"
             src="@/assets/UmeGo_v4_sm.mp4">
      </video>
    </div>
  </div>
</template>

<script>
import {store} from "@/store";
import {mapState} from "vuex";
import {ref} from "vue";
import {useElementVisibility} from "@vueuse/core/index";
export default {
  name: 'Section1',
  props: {
    msg: String
  },
  data: function () {
    return {
      isVisible: false
    }
  },
  computed: mapState(['loading']),
  setup() {
    const target = ref(null);
    const targetIsVisible = useElementVisibility(target);

    return {
      target,
      targetIsVisible,
    }
  },
  created() {
    window.addEventListener('scroll', this.checkSectionVisibility);
  },
  unmounted() {
    window.removeEventListener('scroll', this.checkSectionVisibility);
  },
  updated() {
    this.checkSectionVisibility();
  },
  mounted() {
    this.$nextTick(() => {
      const video = document.getElementById("bg-video")
      video.addEventListener("loadedmetadata", function () {
        store.commit('loading', false);
      });
      // this.checkSectionVisibility();
    });
  },
  methods: {
    checkSectionVisibility () {
      const video = document.getElementById('bg-video');
      if(video) {
        const isInViewport = this.targetIsVisible || window.scrollY < window.innerHeight;
        console.info('isInViewport', isInViewport)
        if (isInViewport)
          video.play();
        else
          video.pause();
      }
    },
  }
}
</script>

<style scoped lang="less">
@import (reference) "src/assets/css/style";

.intro {
  position: relative;
  width: 100%;
  height: calc(~"100vh + 1px - " @headerHeight);
  display: flex;
  align-items: center;
  justify-content: center;

  &__video {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    // z-index: 1;
    video {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  &.in {
    /*.intro__video:before {
      backdrop-filter: blur(0);
    }
    .intro__content {
      text-shadow: 0 0 0 rgba(255,255,255,0);
    }*/
  }
}
</style>
