<template>
  <div class="art-nav-wrapper grid-layout grid-layout__footer_crop">
    <ArticlesNav title='All articles' :articles="articles" class="grid-layout__sidebar"/>
    <div id="retail-transform" class="article-page grid-layout__content">
      <HumanConnection id="HumanConnection"/>
      <div class="art-sep">
        <img src="@/assets/img/retail/woman.jpg" alt=""/>
      </div>

      <DisruptingDigitalDisruption id="DisruptingDigitalDisruption"/>
      <div class="art-quote">
        <div class="container">
          <blockquote>
            <h4 class="art-quote__title">The gift of anticipation</h4>
            <p>
              “What gift do you think a good servant has that separates them from the others? It’s the gift of
              anticipation. I know when they'll be hungry and the food is ready. I know when they will be tired and the
              bed is turned down. I know it before they know it themselves.”
              <br/>
              <br/>
              Gosford Park, Helen Mirren
            </p>
          </blockquote>
        </div>
      </div>

      <RejuvenatedHumanCapital id="RejuvenatedHumanCapital"/>

      <CustomerJourneyTransformation id="CustomerJourneyTransformation"/>

      <div class="art-sep art-sep__phone art-sep__phone_right">
        <div class="container md">
          <img src="@/assets/img/retail/device_3.png" class="art-sep__device" alt=""/>
        </div>
        <img src="@/assets/img/retail/city-speed.png" alt=""/>
      </div>

      <WeaponizingData id="WeaponizingData"/>

      <div class="art-sep art-sep__phone art-sep__phone_right">
        <div class="container md">
          <img src="@/assets/img/retail/device_4.png" class="art-sep__device" alt=""/>
        </div>
        <img src="@/assets/img/retail/road-pink.png" alt=""/>
      </div>

      <GenerativeAI id="GenerativeAI"/>

      <WhatSSOTs id="WhatSSOTs"/>

      <div class="art-sep">
        <img src="@/assets/img/retail/yellow-run.jpg" alt=""/>
      </div>

      <ValueZeroPartyData id="ValueZeroPartyData"/>

      <!--    <div hidden>
            <div class="article-separator" />
          </div>-->
    </div>
    <FooterComponent class="grid-layout__footer"/>
  </div>
</template>

<script>
import {store} from "@/store";
import HumanConnection from "@/components/sections/RetailTransformation/HumanConnection.vue";
import ArticlesNav from "@/components/ArticlesNav.vue";
import FooterComponent from "@/components/Footer.vue";
import DisruptingDigitalDisruption from "@/components/sections/RetailTransformation/DisruptingDigitalDisruption.vue";
import RejuvenatedHumanCapital from "@/components/sections/RetailTransformation/RejuvenatedHumanCapital.vue";
import CustomerJourneyTransformation
  from "@/components/sections/RetailTransformation/CustomerJourneyTransformation.vue";
import WeaponizingData from "@/components/sections/RetailTransformation/WeaponizingData.vue";
import GenerativeAI from "@/components/sections/RetailTransformation/GenerativeAI.vue";
import ValueZeroPartyData from "@/components/sections/RetailTransformation/ValueZeroPartyData.vue";

export default {
  name:       'RetailTransformPage',
  components: {
    ValueZeroPartyData,
    GenerativeAI,
    WeaponizingData,
    CustomerJourneyTransformation,
    RejuvenatedHumanCapital,
    DisruptingDigitalDisruption,
    FooterComponent,
    ArticlesNav,
    HumanConnection,
  },
  data() {
    return {
      articles: [{
        title: 'Human Connection for the Digital Age',
        id:    'HumanConnection'
      }, {
        title: 'Disrupting the Digital Disruption',
        id:    'DisruptingDigitalDisruption'
      }, {
        title: 'Rejuvenated Human Capital',
        id:    'RejuvenatedHumanCapital'
      }, {
        title: 'Customer Journey Transformation',
        id:    'CustomerJourneyTransformation'
      }, {
        title: 'Weaponizing Data',
        id:    'WeaponizingData'
      }, {
        title: 'Generative AI - an evolutionary tale',
        id:    'GenerativeAI'
      }, {
        title: 'What are SSOTs?',
        id:    'WhatSSOTs'
      }, {
        title: 'The Value of Zero Party Data',
        id:    'ValueZeroPartyData'
      }]
    }
  },
  mounted() {
    this.$nextTick(() => {
      const images     = document.querySelectorAll("#retail-transform img")
      let loadedImages = 0;
      images.forEach((image) => {
        image.onload = () => {
          loadedImages++;
          if (loadedImages === images.length) {
            store.commit('loading', false);
          }
        }
      })
    });
  },
  methods: {}
}
</script>

<style scoped lang="less">
@import (reference) "src/assets/css/style";

.art {
  flex-direction: column;
  gap: 0;
}

.wide-content {
  overflow: hidden;
}


</style>
