<template>
  <article class="art">
    <div class="art-section standard container md">
      <div class="art-title">
        <h2 class="art-title__main">Customer Journey Transformation</h2>
        <h3 class="art-title__sub">Placing learning and relationship-building at the heart of the customer journey.</h3>
      </div>
      <p>
        The transition from product-centric to customer-centric thinking requires a fundamental shift in journey
        strategy. Product-centric journeys use recent behavior to predict the “next best action” with an immediate
        conversion goal. Success is measured wholly by conversion metrics. Algorithms are prioritized for conversion
        first, second, and third. Current thinking in the digital economy is that no human contact is required. Chatbots
        handle the majority of triage. Call centers will migrate to simulation as soon as the AI catches up. Upsell,
        cross-sell, retention, and loyalty options are digital, without human interaction or intelligence, using email,
        SMS, retargeting site personalization, and loyalty apps. The current mindset is that scalability and
        repeatability are achieved by eliminating as much human contact as possible. Automation and simulation will rule
        the customer journey in the future. Human interaction is a backstop.
      </p>
    </div>

    <div class="art-sep">
      <img src="@/assets/img/retail/str-lines.jpg" alt=""/>
    </div>

    <div class="art-section column container md">
      <div class="art-title">
        <h3 class="art-title__main">Customer-centric journey</h3>
      </div>
      <div class="content">
        <p>
          In a customer-centric journey strategy, this paradigm changes. Algorithms are prioritized to ingest knowledge,
          learn, be inquisitive, and propel users toward relationships with the brand. “Force feeding” transactions in
          early-stage exploration is eliminated. Human interaction is encouraged. By engaging customers, as any seasoned
          clerk or concierge service would offline, Umego opens the door to opportunities currently lost in the digital
          age.
        </p>
        <p>
          Recent behavior predicts the next question rather than the next action. It assesses which step in the journey
          is most critical to decision-making regarding conversion, retention, loyalty, and advocacy. It learns which
          customers will likely be the twenty percent driving eighty percent of the value to their business. This
          journey approach propels insights related to competitors, objection layers, unfulfilled promises, and lack of
          quality issues. By taking a customer-centric approach to the journey, Umego empowers brands to see through the
          eyes of their most important customers and plan accordingly.
        </p>
      </div>
    </div>

  </article>
</template>

<script>
export default {
  name: 'CustomerJourneyTransformation',
}
</script>
<style scoped lang="less">
@import (reference) "src/assets/css/style";

</style>
