<template>
  <div class="words-wrap">
    <transition name="fade" :duration="500">
    <div class="words-container" v-if="showContainer">
      <div class="word"
           v-for="(item, index) in words" :key="index" v-html="item.content"
           :data-weight="item.weight"
           :class="{'top': index < words.length/2, 'bottom': index >= words.length/2}"
           :style="{
             animationDelay: `${item.priority / 7}s`,
             animationDuration: `${getRandom(5, 30)/10}s`,
             top: `${item.y / 10}rem`,
             left: `${item.x / 10}rem`,
           }"
      />
    </div>
    </transition>
  </div>
</template>

<script>
export default {
  name: 'Section4',
  props: {
    isVisible: {
      type: [Boolean],
      required: true
    }
  },
  data() {
    return {
      showContainer: true,
      words: [{
        weight: 4,
        content: '<strong>CLV</strong>'
      }, {
        weight: 3,
        content: '<strong>trusted advisor</strong>'
      }, {
        weight: 2,
        content: 'Concierge, hospitality'
      }, {
        weight: 3,
        content: '<strong>Customer care</strong>'
      }, {
        weight: 2,
        content: '<strong>ROI</strong>'
      }, {
        weight: 2,
        content: 'Human Connections'
      }, {
        weight: 2,
        content: 'BFD'
      }, {
        weight: 1,
        content: 'trust'
      }, {
        weight: 2,
        content: 'LISTENING'
      }, {
        weight: 3,
        content: 'Is your <strong>brand</strong> ready?'
      }, {
        weight: 3,
        content: '<strong>OMG</strong>'
      }, {
        weight: 2,
        content: 'Intimacy'
      }, {
        weight: 2,
        content: 'Change management'
      }, {
        weight: 2,
        content: 'first-person relationships'
      }, {
        weight: 4,
        content: 'Is your business ready<strong>?</strong>'
      }, {
        weight: 2,
        content: '<strong>New expectations</strong>'
      }, {
        weight: 2,
        content: '<strong>Authenticity</strong>'
      }, {
        weight: 1,
        content: 'Values'
      }, {
        weight: 2,
        content: 'Sharing'
      }, {
        weight: 2,
        content: 'Relevancy'
      }, {
        weight: 1,
        content: 'Brand love'
      }, {
        weight: 3,
        content: 'Are <strong>you</strong> ready?'
      }, {
        weight: 2,
        content: 'Retention'
      }, {
        weight: 4,
        content: '<strong>Get</strong> ready'
      }, {
        weight: 2,
        content: '<strong>EMPATHY</strong>'
      }, {
        weight: 2,
        content: 'kinship'
      }, {
        weight: 3,
        content: '<strong>LOYALTY</strong>'
      }, {
        weight: 1,
        content: 'CARING'
      }, {
        weight: 2,
        content: 'Human Relationships'
      }, {
        weight: 2,
        content: 'Advocacy'
      }, {
        weight: 1,
        content: 'NURTURING'
      }, {
        weight: 2,
        content: '<strong>ANTICIPATION</strong>'
      }, {
        weight: 2,
        content: 'Personalize everything'
      }, {
        weight: 3,
        content: '<strong>I want real people</strong>'
      }]
    }
  },
  watch: {
    isVisible () {
      this.loopWordsAnimation();
    }
  },
  mounted() {
    this.shuffleAnimation();
  },
  methods: {
    getRandom (min, max) {
      return Math.floor(Math.random() * (max - min) + min);
    },
    shuffleAnimation () {
      const listOfPriority = this.words.map((item, index) => index);
      for(let i = 0; i < this.words.length; i++) {
        let randomIndex = this.getRandom(0, listOfPriority.length); // Math.floor(Math.random() * listOfPriority.length);
        this.words[i].priority = listOfPriority[randomIndex] + Math.random();
        listOfPriority.splice(randomIndex, 1);

        this.words[i].x = this.getRandom(4, 7) * (i % 2 > 0 ? 1 : -1);
        this.words[i].y = this.getRandom(4, 7) * (i % 2 > 0 ? 1 : -1);
      }
    },
    loopWordsAnimation () {
      setInterval(() => {
        this.showContainer = false;
        setTimeout(() => {
          this.showContainer = true;
        }, 200);
      }, 12000);
    }
  }
}
</script>

<style lang="less">
@import (reference) "src/assets/css/style";

.words-wrap {
  width: 100%;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 120vh;
  padding: 3rem 0;
  background: @colorBgDark; // #0D0D13;
  color: #fff;
}

.words-container {
  .container;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  gap: 1.5vh 1.5rem;

  .animate();
}

.word {
  position: relative;
  // padding: .05rem .3rem;
  padding: .3rem .3rem;
  font: 0.9rem/1.2rem @fontFamily;
  white-space: nowrap;
  opacity: 0;
  font-weight: 100;

  strong {
    font-weight: 700;
  }
  &[data-weight="1"] {
    font-size: 70%;
  }
  &[data-weight="2"] {
    // font-size: 120%;
  }
  &[data-weight="3"] {
    font-size: 120%;
  }
  &[data-weight="4"] {
    font-size: 170%;
    font-style: italic;
  }
}

.words-wrap.animate__animated {
  .word {
    &.top {
      .animation(wordsAppearTop @duration*3s linear @duration*1s);
      animation-fill-mode: forwards;
    }
    &.bottom {
      .animation(wordsAppearBottom @duration*3s linear @duration*1s);
      animation-fill-mode: forwards;
    }
  }
}

.fade {
  &-enter-from,
  &-leave-to {
    opacity: 0;
  }

  &-enter-active,
  &-leave-active {
    transition: opacity 0.5s ease;
  }
}

@media(max-width: @screen-tablet) {
  .words-wrap {
    padding: 3rem @gap;
  }
}

</style>
