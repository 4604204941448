<template>
  <article class="art">
    <div class="container md">
      <div class="art-section standard">
        <div class="art-title">
          <h1 class="art-title__main big">Join us</h1>
          <h3 class="art-title__sub">
            Ready to make a difference? To transform the way we connect online?
          </h3>
        </div>
        <p>
          Umego isn't your average startup. We're on a mission to bring back a sense of human connection and community
          to the digital age. Imagine your favorite neighborhood hangout – where they know your name and order, where
          conversations flow, and where you feel like you belong. We're building the digital version of that.
        </p>
        <p>
          What are we looking for? People who share our passion for making technology feel less impersonal. Talented
          folks with expertise in these exciting areas:
        </p>
        <ul>
          <li>Ecommerce & Retail Reinvented: Got a knack for seamless online shopping experiences? Intrigued by the
            future of retail?
          </li>
          <li>Concierge with a Twist: We're not just about making things convenient – we're about making people feel
            genuinely cared for.
          </li>
          <li>Sales Gurus: If you believe in empowering teams to connect, not just close, we need you! Think dynamic
            training and coaching strategies.
          </li>
          <li>Live Streaming Pros: Want to revolutionize online interaction? Us too! Bring your cutting-edge tech
            knowledge and boundless creativity.
          </li>
          <li>AI with Heart: We believe in harnessing the power of technology to foster genuine human connection.</li>
        </ul>
        <p>
          Our CEO, Sean Dunn, sums it up best: "We're building a billion-person coffee shop - while tapping into new
          capabilities to propel familiarity, intimacy, trust, empathy, and care at a distance.”
        </p>
        <p>
          Does this resonate with you? If so, we definitely want to talk. This isn't just about filling jobs; it's about
          building a team who shares our vision of making the digital world a little more human. As William Butler Yeats
          said, "There are no strangers here, only friends you haven't met yet.” Let's change that, together.
        </p>
      </div>
    </div>
  </article>
</template>

<script>
export default {
  name: 'JoinUs',
}
</script>
<style scoped lang="less">
@import (reference) "src/assets/css/style";

.art-section {
  overflow: hidden;
}


</style>
