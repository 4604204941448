<template>
  <div class="section5">
    <div class="bg">
      <video id="bg-video" class="bg__video" autoplay loop muted playsinline preload="auto"
             src="@/assets/GettyImages-1222183348.mp4">
      </video>
    </div>
    <div class="content container md">
      <div class="static">
        If you want a person’s loyalty,
      </div>
      <div class="live">
        <ul>
          <li>know them.</li>
          <li>talk to them.</li>
          <li>care about them.</li>
          <li>listen to them.</li>
          <li>nurture them.</li>
          <li>serve them.</li>
          <li>know them.</li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Section5',
}
</script>

<style scoped lang="less">
@import (reference) "src/assets/css/style";

.section5 {
  position: relative;
  width: 100%;
  height: 100vh;
  background: @colorBgDark;
  @media(max-width: @screen-mobile_sm) {
    height: auto;
  }
}

.content {
  position: relative;
  height: 100%;
  display: flex;
  align-items: center;
  gap: @gap*0.66;
  font: 700 52px/100% @fontFamily;
  color: #fff;

  .static {

  }
  .live {
    position: relative;
    height: 52px;
    overflow: hidden;
  }
  .live ul {
    position: relative;
    .animation(wordsSpin 12s linear infinite running);
    animation-fill-mode: forwards
  }
  @media(max-width: @screen-tablet) {
    font: 700 36px/52px @fontFamily;
  }
  @media(max-width: @screen-mobile) {
    font: 700 24px/52px @fontFamily;
  }
  @media(max-width: @screen-mobile_sm) {
    flex-direction: column;
    padding-top: 10vh;
    padding-bottom: 10vh;
    text-align: center;
  }
}

.bg {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;

  &__video {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  &:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: linear-gradient(0deg, rgba(13, 18, 19, 0.50) 0%, rgba(13, 18, 19, 0.50) 100%);
  }
}

</style>
