<template>
  <div class="home-page">
    <Section1 class="waiting" />

    <Section2 />
    <Section3 />
    <Section4 class="waiting words-section animate" :is-visible="runWordsAnimation" />
    <Section6 />
    <Section5 class="waiting" />


    <Section7 />
    <Section8 />
    <Section12 />
    <Section9 />
    <Section10 />
    <Section11 />
  </div>


<!--  <div hidden>
    <Section7 />
    <Section8 />
    <Section9 />
    <Section10 />
  </div>-->
  <FooterComponent />
</template>

<script>
import Section1 from "@/components/sections/Home/Section1";
import Section2 from "@/components/sections/Home/Section2";
import Section3 from "@/components/sections/Home/Section3";
import Section4 from "@/components/sections/Home/Section4";
import Section5 from "@/components/sections/Home/Section5";
import Section6 from "@/components/sections/Home/Section6";
import Section7 from "@/components/sections/Home/Section7";
import Section8 from "@/components/sections/Home/Section8";
import Section9 from "@/components/sections/Home/Section9.vue";
import Section10 from "@/components/sections/Home/Section10.vue";
import FooterComponent from "@/components/Footer.vue";
import Section11 from '@/components/sections/Home/Section11.vue'
import Section12 from '@/components/sections/Home/Section12.vue'

export default {
  name: 'HomePage',
  components: {
    Section12,
    Section11,
    FooterComponent,
    Section10,
    Section9,
    Section8,
    Section7,
    Section6,
    Section5,
    Section4,
    Section3,
    Section2,
    Section1
  },
  data () {
    return {
      offsetTop: 0,
      animationStartPos: 0,
      animationEndPos: 0,
      runWordsAnimation: false
    }
  },
  created () {
    window.addEventListener('scroll', this.onScroll);
  },
  unmounted () {
    window.removeEventListener('scroll', this.onScroll);
  },
  mounted() {
    this.animationStartPos = -window.innerHeight*0.01;
    this.animationEndPos = window.innerHeight*0.65;
    this.offsetTop = document.documentElement.scrollTop;
  },
  watch: {
    offsetTop () {
      this.callbackFunc();
    }
  },
  methods: {
    onScroll () {
      this.offsetTop = document.documentElement.scrollTop;
    },
    isElementInViewport(el) {
      var rect = el.getBoundingClientRect();
      return (
          rect.top >= this.animationStartPos &&
          rect.top <= this.animationEndPos
      )
    },
    callbackFunc() {
      let items = document.querySelectorAll(".waiting");
      for (var i = 0; i < items.length; i++) {
        if (this.isElementInViewport(items[i])) {
          items[i].classList.add("animate__animated");
          items[i].classList.remove("waiting");

          if(items[i].classList.contains('words-section')) {
            this.runWordsAnimation = true;
          }
        }
      }
    }
  }
}
</script>

<style scoped lang="less">
@import (reference) "src/assets/css/style";

.home-page {
  background: linear-gradient(180deg, #11093F 0%, #0C053D 100%);
}

</style>
