<template>
  <article class="art platform container md">
    <div class="content ">
      <div class="content_title">UmeChat</div>
      <div class="content_text">
        <p>is customer-centric generative AI integrated into brand-owned, earned, and paid digital channels, built with
          perpetually evolving Zero Party Human Intelligence. First-touch personalization begins here. UmeChat is
          curious and a good listener. It starts conversations with the brand’s personality, voice and essence. It uses
          customer-centric inputs to pair each customer with an ideal brand ambassador and proactively sets the
          engagement level. It’s LLM learns about each customer’s propensity to purchase and stay during onboarding.</p>
      </div>
      <div class="content_tags">Listen. Learn. Connect. Support.</div>
    </div>
    <div class="screenshot">
      <img src="@/assets/img/platform/UmeChat.png" />
    </div>

  </article>
</template>

<script>
export default {
  name: 'UmeChat',
}
</script>
<style scoped lang="less">
@import (reference) "src/assets/css/style";

</style>
