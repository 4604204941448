<template>
  <div class="article-page">
    <CustomerCentricity/>
    <ModernizingTraditions/>
    <UmegoBenefits/>

    <FooterComponent/>
  </div>
</template>

<script>
import {store} from "@/store";

import UmegoBenefits from "@/components/sections/Industries/UmegoBenefits.vue";
import ModernizingTraditions from "@/components/sections/Industries/ModernizingTraditions.vue";
import FooterComponent from "@/components/Footer.vue";
import CustomerCentricity from "@/components/sections/Industries/CustomerCentricity.vue";

export default {
  name:       'IndustriesPage',
  components: {
    CustomerCentricity,
    FooterComponent,
    ModernizingTraditions,
    UmegoBenefits,
  },
  data() {
    return {}
  },
  mounted() {
    this.$nextTick(() => {
      store.commit('loading', false);
    });
  },
  methods: {}
}
</script>

<style scoped lang="less">
@import (reference) "src/assets/css/style";

.article-page {
  display: flex;
  flex-direction: column;
  gap: @gap*2;
}
</style>
