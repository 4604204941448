<template>
  <article class="art platform container md">
    <div class="content ">
      <div class="content_title">UmeShop LIVE</div>
      <div class="content_text">
        <p>(1:1/1:S) provides video, text, and chat livestream capabilities within the brand’s digital ecosystem,
          directly embedded into e-commerce, social shopping, and clienteling functionality, enable shared shopping
          experiences. The platform hosts live human-to-human video engagement and chat-based conversations connected
          through popular messaging apps like SMS, WhatsApp, Instagram, and Facebook Messenger. Every comment,
          sentiment, concern, curiosity, and decision is captured during live events.</p>
      </div>
      <div class="content_tags">Trust. Empathize. Care. Anticipate.</div>
    </div>
    <div class="screenshot">
      <img src="@/assets/img/platform/UmeShop.png" />
    </div>

  </article>
</template>

<script>
export default {
  name: 'UmeShop',
}
</script>
<style scoped lang="less">
@import (reference) "src/assets/css/style";

</style>
