<template>
  <article class="art">
    <div class="art-section standard container md">
      <div class="art-title">
        <h2 class="art-title__main">The Value of Zero Party Data</h2>
        <h3 class="art-title__sub">Forget transactional data and robotic interactions. The key to customer loyalty isn’t
          more algorithms.</h3>
      </div>
      <p>
        Twenty years ago my team built an early prototype of a sales enablement platform with Sun Microsystems and Cisco
        Systems when we were envisioning the workplace of the future that turned out to be the COVID workplace. Where
        workforces were disparate, knowledge transfer and training happened at a distance, and KPIs were met with little
        face-to-face supervision. We built a model for teams to work together while apart, unifying around a global
        value proposition. Of course, technology is a three tier system. This meant extending the model to telcos, SIs,
        and consultants.
      </p>
      <p>
        Our location in Northern California gave us access to the Napa and Sonoma wine regions. Diageo Chateau and
        Estate wines became our first client. Within months, sales enablement exploded across the organization. Soon we
        were running trade communication for Diageo’s wine, spirit and beer divisions. Over time, we expanded into
        hospitality and concierge across tasting rooms, cellar parties, travel and events, and hotel and resort
        partnerships. In 2005, after the Granholm v. Heald decision, we took over development of wine club and
        e-commerce offerings. For the next eighteen years, our team helped hundreds of wine brands triangulate 3-tier
        on/off-premise distribution, online digital marketing and ecommerce, and in-person hospitality and concierge
        services.
      </p>
    </div>

    <div class="art-sep">
      <img src="@/assets/img/retail/light-running-left.jpg" alt=""/>
    </div>

    <div class="art-section column container md">
      <div class="art-title">
        <h3 class="art-title__main">In-person versus online data</h3>
      </div>
      <div class="content">
        <p>
          We were extremely lucky, and privileged, to experience the industry across all revenue functions (excluding
          grape sales). This vantage point provided access to all kinds of data sets from all types of touchpoints and
          stakeholders. Over time, it became clear that the data from digital marketing campaigns was fundamentally
          different from the data in the tasting rooms, cave parties, and club memberships. Online data was functional,
          but offered little customer-centric insights. In-person data was richly customer-centric while also inclusive
          of all the functional insights. .
        </p>
        <p>
          We learned that understanding of the customer’s interests, occasions, likes and dislikes, lifestyle,
          frequencies, varietals and how wine fit into their lives was vastly more indicative of their propensity to
          purchase and stay than the transactional and conversion data from GA, CRM and POS. Even as ESP, CDP, and SSOT
          enhanced what CRM could capture and activate, we could not extract the same level of intelligence, sentiment,
          or value from the digital data. It was impersonal, distant, automated, and robotic. Conversely, as attributes
          like trust, empathy, care and anticipation seeped into live conversations, the Zero Party proved more valuable
          than gold
        </p>
      </div>
    </div>

    <div class="art-sep">
      <img src="@/assets/img/retail/red-men.jpg" alt=""/>
    </div>

    <div class="art-section column container md">
      <div class="art-title">
        <h3 class="art-title__main">Next-generation Zero Party data</h3>
      </div>
      <div class="content">
        <p>
          Harnessing Zero Party Human Intelligence was the genesis of Umego. It lives at the intersection of three
          capabilities (our own Gordian Knot). First, with the introduction of AI-powered LLMs, trained using our unique
          behavioral and social science inputs, we unlock unlimited opportunities to appreciate, understand, and respect
          customers. Second, in developing our customer connection platform, we can now nurture relationships at a
          distance that feel personal and face-to-face.
        </p>
        <p>
          Finally, none of it works without human connection. Our sales enablement pedigree helps to transform sales
          organizations into a concierge, hospitality, and personal shopper direct selling model. What started at Sun
          Microsystems over twenty years ago as a way for people to work far apart has come full circle and is now being
          used to bring people back together. That’s the power of Zero Party Human Intelligence.
        </p>
      </div>
    </div>
  </article>
</template>

<script>
export default {
  name: 'ValueZeroPartyData',
}
</script>
<style scoped lang="less">
@import (reference) "src/assets/css/style";

</style>
