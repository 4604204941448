<template>
  <article class="art">
    <div class="container md">
      <div class="art-section standard">
        <div class="art-title">
          <h1 class="art-title__main big">Who we are</h1>
          <h3 class="art-title__sub">
            Looking for a team with the know-how to propel your brand forward?
          </h3>
        </div>
        <p>
          Our leadership team brings over 80 years of combined experience in digital commerce, performance analytics,
          AI/ML, sales enablement, crypto mining, GPU management, MarTech, CRM, brand development, performance
          marketing, and CDP. Together, we helped Astound Commerce expand its market share and surpass $100M in annual
          sales.
        </p>
        <p>
          Individually, we empower clients across luxury apparel, destination travel, alcohol beverage, beauty, HLS,
          jewelry, footwear, and electronics to build meaningful and lasting connections with their customers. Our
          passion is to reintroduce core values like trust, empathy, care, and anticipation into the digital economy,
          fueling sustainable revenue growth at scale for our clients.
        </p>
      </div>
    </div>
  </article>
</template>

<script>
export default {
  name: 'WhoWeAre',
}
</script>
<style scoped lang="less">
@import (reference) "src/assets/css/style";

.art-section {
  overflow: hidden;
}


</style>
