<template>
  <router-link :to="PATHS.home.root">
    <div class="logo">
      <svg xmlns="http://www.w3.org/2000/svg" width="130" height="32" viewBox="0 0 170 41" fill="none">
        <g clip-path="url(#clip0_332_4207)">
          <path d="M159.408 30.4211C157.521 30.4211 155.79 29.9789 154.215 29.0946C152.639 28.2102 151.395 27.0016 150.481 25.4688C149.567 23.936 149.11 22.2262 149.11 20.3397C149.11 18.4728 149.567 16.7729 150.481 15.24C151.395 13.6876 152.639 12.4691 154.215 11.5848C155.79 10.7005 157.521 10.2583 159.408 10.2583C161.275 10.2583 162.995 10.7005 164.571 11.5848C166.166 12.4691 167.42 13.6876 168.334 15.24C169.268 16.7729 169.734 18.4728 169.734 20.3397C169.734 22.2262 169.268 23.936 168.334 25.4688C167.42 27.0016 166.166 28.2102 164.571 29.0946C162.995 29.9789 161.275 30.4211 159.408 30.4211ZM159.408 26.1468C160.477 26.1468 161.44 25.9012 162.295 25.4099C163.151 24.9186 163.822 24.2405 164.308 23.3759C164.795 22.4916 165.038 21.4795 165.038 20.3397C165.038 19.1999 164.795 18.1977 164.308 17.333C163.822 16.4486 163.151 15.7608 162.295 15.2695C161.44 14.7782 160.477 14.5325 159.408 14.5325C158.319 14.5325 157.346 14.7782 156.49 15.2695C155.654 15.7412 154.993 16.4191 154.507 17.3035C154.04 18.1878 153.807 19.1999 153.807 20.3397C153.807 21.4795 154.04 22.4916 154.507 23.3759C154.993 24.2602 155.654 24.948 156.49 25.4393C157.346 25.911 158.319 26.1468 159.408 26.1468Z" fill="#9588E1"/>
          <path d="M138.168 30.4211C136.243 30.4211 134.483 29.9789 132.888 29.0946C131.312 28.2102 130.068 27.0016 129.154 25.4688C128.24 23.936 127.783 22.2262 127.783 20.3397C127.783 18.4531 128.24 16.7434 129.154 15.2106C130.068 13.6777 131.312 12.4691 132.888 11.5848C134.483 10.7005 136.243 10.2583 138.168 10.2583C139.918 10.2583 141.494 10.6317 142.894 11.3784C144.294 12.1056 145.461 13.157 146.395 14.5325L143.156 17.274C142.515 16.37 141.776 15.692 140.939 15.24C140.123 14.7684 139.17 14.5325 138.08 14.5325C136.953 14.5325 135.961 14.7782 135.105 15.2695C134.249 15.7608 133.598 16.4486 133.15 17.333C132.703 18.1977 132.48 19.1999 132.48 20.3397C132.48 21.4992 132.713 22.5309 133.18 23.4348C133.666 24.3388 134.356 25.0463 135.251 25.5572C136.145 26.0485 137.186 26.2942 138.372 26.2942C139.364 26.2942 140.249 26.1173 141.027 25.7636C141.805 25.4099 142.437 24.9186 142.923 24.2897V23.1106H138.11V19.1016H147.503V25.0856C146.628 26.6774 145.364 27.9646 143.711 28.9472C142.058 29.9298 140.21 30.4211 138.168 30.4211Z" fill="#9588E1"/>
          <path d="M116.052 30.539C114.056 30.539 112.254 30.0935 110.646 29.2026C109.038 28.2856 107.781 27.0671 106.873 25.5474C105.965 24.0015 105.511 22.2721 105.511 20.3593C105.511 18.4727 105.952 16.7565 106.834 15.2105C107.742 13.6384 108.961 12.4069 110.49 11.516C112.02 10.6251 113.719 10.1797 115.586 10.1797C117.349 10.1797 118.918 10.5727 120.292 11.3588C121.666 12.1449 122.729 13.2322 123.482 14.621C124.259 16.0098 124.648 17.5426 124.648 19.2195C124.648 20.2152 124.532 21.1847 124.298 22.128H109.984V18.3941H120.253L119.553 18.7872C119.605 17.9225 119.45 17.1626 119.086 16.5075C118.723 15.8525 118.217 15.3546 117.569 15.0141C116.921 14.6472 116.195 14.4638 115.391 14.4638C114.484 14.4638 113.654 14.7127 112.902 15.2105C112.175 15.6822 111.605 16.3634 111.19 17.2543C110.776 18.119 110.568 19.1278 110.568 20.2807C110.568 21.4598 110.802 22.4817 111.268 23.3464C111.735 24.2111 112.396 24.8662 113.252 25.3116C114.108 25.757 115.093 25.9798 116.208 25.9798C117.219 25.9798 118.153 25.8094 119.008 25.4688C119.89 25.1282 120.746 24.6434 121.576 24.0146L123.754 27.6305C122.664 28.5738 121.472 29.2944 120.176 29.7922C118.905 30.29 117.53 30.539 116.052 30.539Z" fill="#E6E3F8"/>
          <path d="M72.505 10.612H77.3286V15.8394L75.3446 16.5075C75.604 14.6472 76.3949 13.1275 77.7171 11.9483C79.0657 10.7692 80.7513 10.1797 82.7736 10.1797C84.3553 10.1797 85.6907 10.5465 86.78 11.2802C87.895 12.0139 88.7117 13.0358 89.2305 14.3459L87.2081 15.1713C87.7263 13.7039 88.6733 12.5117 90.0471 11.5946C91.4474 10.6513 93.0681 10.1797 94.9091 10.1797C97.1651 10.1797 98.9413 10.8872 100.238 12.3021C101.535 13.717 102.183 15.6167 102.183 18.0011V30.0673H96.9321V18.7479C96.9321 17.595 96.6205 16.6779 95.9984 15.9967C95.3763 15.3154 94.5465 14.9747 93.5088 14.9747C92.8087 14.9747 92.1866 15.132 91.642 15.4464C91.1231 15.7608 90.7082 16.1931 90.3972 16.7434C90.112 17.2937 89.9697 17.9225 89.9697 18.63V30.0673H84.7186V18.7479C84.7186 17.595 84.4075 16.6779 83.7848 15.9967C83.1628 15.3154 82.3329 14.9747 81.2959 14.9747C80.2325 14.9747 79.3768 15.3154 78.7283 15.9967C78.0804 16.6779 77.7561 17.595 77.7561 18.7479V30.0673H72.505V10.612Z" fill="#E6E3F8"/>
          <path d="M59.2775 30.539C57.5921 30.539 56.0622 30.1722 54.6878 29.4385C53.3394 28.6786 52.2762 27.6175 51.4983 26.2549C50.7464 24.8924 50.3704 23.3072 50.3704 21.4992V10.6121H55.6213V21.9315C55.6213 23.0321 55.9585 23.936 56.6327 24.6435C57.3327 25.3248 58.2144 25.6653 59.2775 25.6653C60.2889 25.6653 61.1316 25.3248 61.8058 24.6435C62.5059 23.936 62.856 23.0321 62.856 21.9315V10.6121H68.107V21.4992C68.107 23.3072 67.7179 24.8924 66.9403 26.2549C66.1879 27.6175 65.1377 28.6786 63.7897 29.4385C62.4411 30.1722 60.9371 30.539 59.2775 30.539Z" fill="#E6E3F8"/>
          <path opacity="0.94" d="M0.000366577 22.2391L10.835 16.0054V24.583L28.428 34.4413L17.6343 40.7187L0.000366211 30.5378L0.000366577 22.2391Z" fill="#9588E1"/>
          <path d="M-0.000183105 22.2115L10.8325 16.0015V24.588L3.00258e-05 30.5372L-0.000183105 22.2115Z" fill="#6D5CD6"/>
          <path d="M28.4256 34.4361L18.0392 28.6155L24.7748 24.5968V4.17578L35.2592 10.1893V30.5415L28.4256 34.4361Z" fill="#D4CFF3"/>
          <path d="M18.0379 28.6153L24.772 24.5991L35.2642 30.5339L28.417 34.4507L18.0379 28.6153Z" fill="#B9B1EB"/>
          <path d="M17.6377 12.0694L24.7781 16.0058V4.18158L17.638 0.00708008L0.000427777 10.189L0.000427246 22.2417L17.6377 12.0694Z" fill="#EEECFA"/>
          <path d="M24.769 15.9985L17.6353 12.0743V0L24.769 4.17656V15.9985Z" fill="#E6E3F8"/>
        </g>
        <defs>
          <clipPath id="clip0_332_4207">
            <rect width="170" height="40.7186" fill="white"/>
          </clipPath>
        </defs>
      </svg>
    </div>
  </router-link>
</template>

<script>
import {PATHS} from "@/router";

export default {
  name: 'Logo',
  computed: {
    PATHS() {
      return PATHS
    }
  },
  props: {
    tagline: Boolean
  }
}
</script>

<style scoped lang="less">
@import (reference) "src/assets/css/style";

.logo {
  display: flex;
  align-items: flex-end;
  gap: (0.5rem);
}

</style>
