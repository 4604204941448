<template>
  <article class="art platform">
    <div class="art-section standard container md">
      <div class="art-title">
        <h3 class="art-title__main big">The Hub</h3>
      </div>
      <div class="flex-column">
        <div class="flex-row">
          <div>
            <p><span class="lead-in">The Hub</span> is our command center. It combines brand ambassador training content
              with real-time performance analytics dashboards. The dynamic dashboards display deep insights, including
              visits, engagement, transcripts, journeys, sentiment, outbound effectiveness, purchase history, propensity
              models, and real-time CLV projections.</p>
            <p>The Hub also enables 1:1 outbound communication between brand ambassadors and customers via email, SMS
              and other popular messaging platforms. UmeHub is also a calendaring tool, enabling brand ambassadors to
              schedule customer engagements.</p>
          </div>
          <div class="screenshot">
            <img src="@/assets/img/platform/TheHub.png"/>
          </div>
        </div>
      </div>
    </div>
  </article>
</template>

<script>
export default {
  name: 'TheHub',
}
</script>
<style scoped lang="less">
@import (reference) "src/assets/css/style";

.companies {
  height: 56px;
  display: flex;
  gap: @gap;
  justify-content: space-around;

  img {
    height: 100%;
    align-self: center;
  }
}

.header {
  display: flex;
  gap: @gap*2;
  margin-bottom: @gap;

  img {
    object-fit: contain;
    align-self: center;
  }
}

.art-section {
  padding-bottom: initial;
}

.art-subtitle {
  color: #F2F0F7;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 125%; /* 30px */
  letter-spacing: -0.384px;
  margin-top: @gap;
}

.art {
  .lead-in {
    font-weight: 700;
    color: #F2F0F7;
  }

  ul {
    padding-left: @gap;
    margin-bottom: @gap;

    li {
      margin-bottom: initial;
    }
  }
}
</style>
