<template>
  <article class="art">
      <div class="column container md ">
        <div class="content">
          <div class="content_title">Umego is not for everyone, and it is not for every type of business or customer.</div>
          <div class="content_text">
            <p>It is for brands that are focused on customer-centricity over product and conversion-centricity. It guides brands through expert selling strategies, customer heterogeneity, and personalization that strives to deliver world-class hospitality, concierge, and personal shopper intelligence. </p>
            <p>We have identified industries where great customer-brand relationships are built with category knowledge, emotional intelligence, and a genuine desire for connection via trust, empathy, care and the art of anticipation.</p>
          </div>
        </div>
      </div>
      <div>
        <div class="categories">
          <div>
            <img src="@/assets/img/industries/icons/icon-travel-neutral.svg"/>
            <p>Travel & Hospitality</p>
          </div>
          <div>
            <img src="@/assets/img/industries/icons/icon-beauty-neutral.svg"/>
            <p>Beauty</p>
          </div>
          <div>
            <img src="@/assets/img/industries/icons/icon-wine-neutral.svg"/>
            <p>Wine</p>
          </div>
          <div>
            <img src="@/assets/img/industries/icons/icon-apparel-neutral.svg"/>
            <p>Apparel</p>
          </div>
          <div>
            <img src="@/assets/img/industries/icons/icon-hobby-neutral.svg"/>
            <p>Hobby & Leisure</p>
          </div>
          <div>
            <img src="@/assets/img/industries/icons/icon-footwear-neutral.svg"/>
           <p>Footwear</p>
          </div>
          <div>
            <img src="@/assets/img/industries/icons/icon-speciality-neutral.svg"/>
            <p>Specialty</p>
          </div>
          <div>
            <img src="@/assets/img/industries/icons/icon-luxury-neutral.svg"/>
            <p>Luxury</p>
          </div>
          <div>
            <img src="@/assets/img/industries/icons/icon-jewelery-neutral.svg"/>
            <p>Jewelry</p>
          </div>
          <div>
            <img src="@/assets/img/industries/icons/icon-electronics-neutral.svg"/>
            <p>Electronics</p>
          </div>
          <div>
            <img src="@/assets/img/industries/icons/icon-sports-neutral.svg"/>
            <p>Sports & Fitness</p>
          </div>
          <div>
            <img src="@/assets/img/industries/icons/icon-diy-neutral.svg"/>
            <p>DIY</p>
          </div>
        </div>
      </div>
  </article>
</template>

<script>
export default {
  name: 'CustomerCentricity',
}
</script>
<style scoped lang="less">
@import (reference) "src/assets/css/style";
article{
  padding-top: @gap*4;
}
.categories{
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: @gap*2 @gap;
  text-align: center;
  font-size: 14px;
  padding-right: @gap*2;
}
.art{
  display: flex;
  gap: @gap*0;
  .art-title{
    display: flex;
    flex-direction: column;
    gap: @gap*2;
    font-size: 28px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
    &__main{
      font-size: 54px;
      font-style: normal;
      font-weight: 700;
      line-height: 120%;
      color:#F3F2F7;
    }
  }
  .content{
    padding-top: 0;
    .content_title{
      font-size: 40px;
    }
  }
  @media(max-width: @screen-tablet) {
    gap: @gap*3;
    .art-title{
      font-size: 20px;
      &__main{
        font-size: 42px;
      }
    }
  }
  @media(max-width: @screen-mobile) {
    gap: @gap*2;
    .art-title{
      font-size: 16px;
      &__main{
        font-size: 36px;
      }
    }
  }
}
</style>
