<template>
  <article class="art platform container md">
    <div class="content ">
      <div class="content_title">Seamlees integration</div>
      <div class="content_text">
        <p>into existing e-commerce, martech, loyalty, CRM, ESP, CDP, POS, finance, and accounting platforms owned or
          licensed. Umego can become an integration bus, creating a customer-centric single source of truth between all
          customer-facing platforms. Seamless integration across the brand’s digital ecosystem will offer customers a
          concierge relationship that fosters higher retention and longer loyalty.</p>
      </div>
      <div class="content_tags">Influence. Inspire. Sponsor. Supercharge.</div>
    </div>
    <div class="screenshot">
      <img src="@/assets/img/platform/SeamlessIntegration.png" />
    </div>

  </article>
</template>

<script>
export default {
  name: 'SeamlessIntegration',
}
</script>
<style scoped lang="less">
@import (reference) "src/assets/css/style";

</style>
