<template>
<!--  <nav>
    <router-link to="/">Home</router-link> |
    <router-link to="/about">about</router-link>
  </nav>-->
  <div>
    <Preloader />
    <div class="wrapper" :class="{'loaded': !loading}">
      <HeaderComponent :nav-items="navList" />
      <div class="wrapper__content">
        <router-view/>
      </div>
<!--      <FooterComponent :nav-items="navList" />-->
    </div>
  </div>
</template>
<script>
import HeaderComponent from "@/components/Header";
import {mapState} from "vuex";
import Preloader from "@/components/Preloader.vue";
// import {PATHS} from "@/router";

export default {
  components: {Preloader, /*FooterComponent,*/ HeaderComponent},
  computed: mapState(['loading']),
  data: function () {
    return {
      showMenu: false,
      /*navList: [
        {
          name: 'RetailTransformation transformation',
          path: PATHS.retail.root,
          // submenu: [{
          //   name: '0',
          //   path: PATHS.retail.root
          // }, {
          //   name: '1',
          //   path: PATHS.retail.root
          // }, {
          //   name: '2',
          //   path: PATHS.retail.root
          // }, {
          //   name: '3',
          //   path: PATHS.retail.root
          // }, {
          //   name: '4',
          //   path: PATHS.retail.root
          // }, {
          //   name: '5',
          //   path: PATHS.retail.root
          // }, {
          //   name: '6',
          //   path: PATHS.retail.root
          // }, {
          //   name: '7',
          //   path: PATHS.retail.root
          // }]
        },
        {
          name: 'Platform',
          path: PATHS.platform.root
        },
        // {
        //   name: 'industries',
        //   path: PATHS.home.root
        // }, {
        //   name: 'Cases',
        //   path: PATHS.retail.root
        // }, {
        //   name: 'about',
        //   path: PATHS.retail.root
        // }, {
        //   name: 'Join',
        //   path: PATHS.retail.root
        // }
      ]*/
    }
  },
}
</script>
<style lang="less">
@import "assets/css/style";

.wrapper {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  opacity: 0;
  .animate(@duration);

  &__content {
    flex-grow: 2;
    //display: flex;
  }
  &.loaded {
    opacity: 1;
  }
}

</style>
