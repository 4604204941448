// import Vue from 'vue';
import Vuex from 'vuex';

// Vue.use(Vuex);

export const store = new Vuex.Store({
  state: {
    loading: true,
    duration: 250 // '0.25s'
  },
  getters: {
    /*getDelay: function (state, priority) {
      return (state.duration * priority);
    }*/
  },
  mutations: {
    loading: function (state, isLoading) {
      state.loading = isLoading;
    },
  },
  actions: {},
});


