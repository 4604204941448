<template>
  <article class="art">
    <div class="art-section standard container md">
      <div class="header">
        <img src="@/assets/img/platform/AIpoweredIntelligenceEngine.png"/>
        <div>
          <div class="art-title">
            <h3 class="art-title__main big">AI-powered Intelligence Engine</h3>
          </div>
          <p>Umego offers a real-time, virtual personal assistant within the brand ambassador application. It can be
            personalized to the unique characteristics of each brand ambassador. During live engagements it feeds
            real-time insights, recommendations, sentiment, and history into the conversation, and can be dialed up or
            down. It maximizes familiarity, helping the ambassador to build meaningful and lasting customer
            relationships.
            It also provides contemporaneous notes, helping the ambassador rate calls, request feedback, and launch
            outbound communication.</p>
        </div>
      </div>
      <div class="content">
        <p><span class="lead-in">Our proprietary AI</span> augments transactional and demographic data with omnichannel,
          zero-party data intelligence enriched through the conversations between thousands of brand ambassadors and
          customers. The conversations are analyzed before, during, and after engagements. The LLMs are trained using
          advanced psychology, social science and behavioral science methodologies. What emerges is a holistic view of
          the customer from an advanced psychographic perspective, with attributes like trust, empathy, care, integrity,
          thoughtfulness, honesty, fairness, loyalty, dependability, authenticity, and independence wrapped around
          traditional product and service attributes. Our AI-powered customer intelligence platform delivers this value
          to customers who feel understood, respected, and loved.</p>
        <p><span class="lead-in">The intelligence engine</span> uses a combination of generative AI models, AI
          databases, traditional databases, and analytics tools to offer:</p>
        <ul>
          <li>Product recommendations</li>
          <li>Conversation topic recommendations</li>
          <li>Recommendations on when and how to follow up with a customer</li>
          <li>Automated customer profile generation</li>
          <li>Ambassador feedback</li>
          <li>CLV and purchase behavior predictions</li>
          <li>Learning across customers</li>
          <li>Intake of product catalogs</li>
          <li>Intake of brand ambassador notes</li>
          <li>Conversation archiving</li>
          <li>Compliance with all applicable privacy regulations</li>
        </ul>
        <p>As the LLMs mature, they enhance AI-generated recommendations and feedback that are specific to each brand
          ambassador and each customer engagement. With each engagement, the LLMs get smarter about every nuance of the
          customer-brand relationship. They optimize customer profiling, predicted actions, propensity to purchase, stay
          and advocate, and will advise on customer-level investment against projected CLV.</p>
        <p><span class="lead-in">Immersion</span> is dedicated to making live video and chat engagements interactive and
          fun. These features vary by industry but can include 3D mobile scanning, image recognition, loyalty
          integration, product testing, sharing and saving functionality, group chat, community, and other
          customer-centric experiences. All vertical-specific features aim to add real-world essence to the digital
          experience. Umego will constantly innovate in this area, adding new features as the customer, brand
          ambassador, and company demands evolve.</p>
      </div>
    </div>

  </article>
</template>

<script>
export default {
  name: 'AIpoweredIntelligenceEngine',
}
</script>
<style scoped lang="less">
@import (reference) "src/assets/css/style";

.header {
  display: flex;
  gap: @gap*2;
  margin-bottom: @gap;

  img {
    object-fit: contain;
    align-self: flex-start;
  }
}

.art-section {
  padding-bottom: initial;
}

.art-subtitle {
  color: #F2F0F7;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 125%; /* 30px */
  letter-spacing: -0.384px;
  margin-top: @gap;
}

.art {
  .lead-in {
    font-weight: 700;
    color: #F2F0F7;
  }

  ul {
    padding-left: @gap;
    margin-bottom: @gap;

    li {
      margin-bottom: initial;
    }
  }
}
</style>
