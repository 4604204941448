<template>
  <article class="art platform container md">
    <div class="content ">
      <div class="content_title">UmeShow LIVE</div>
      <div class="content_text">
        <p>(1:M) leverages the AI-powered customer intelligence within our proprietary LLMs to deliver personalized
          human simulation - brand ambassador personal assistants, chatbots, text and SMS messaging, call center,
          in-store kiosks, sales enablement training and coaching, and omnichannel support. This avatar-based feature
          combines a sophisticated profile of your ideal customer with a quintessential, white glove brand ambassador
          personality. This feature enables simulated human services for customers that do not require brand
          ambassador-level concierge relationships.</p>
      </div>
      <div class="content_tags">Search. Browse. Shop. Sizzle.</div>
    </div>
    <div class="screenshot">
      <img src="@/assets/img/platform/UmeShow.png" />
    </div>

  </article>
</template>

<script>
export default {
  name: 'UmeShow',
}
</script>
<style scoped lang="less">
@import (reference) "src/assets/css/style";


</style>
