<template>
  <article class="art">
      <div class="column container md ">
        <div class="content">
          <div class="content_title">Umego Benefits</div>
          <div class="content_text">
            <ul>
              <li><span class="benefit">Deep Learning Insights:</span> The LLM will identify preferences, patterns, personality, and propensities, assisting brand ambassadors in tailoring conversations and recommendations.</li>
              <li><span class="benefit">Emotional Intelligence:</span> Recognizing and responding to emotional cues in contextual ways will enable ambassadors to offer personal, meaningful, caring, and empathetic responses.</li>
              <li><span class="benefit">Proactive Engagement:</span> Based on individual and aggregate analysis, personalized advice, assistance, and recommendations will be shared proactively.</li>
              <li><span class="benefit">Continuous Learning and Development:</span> Training and coaching based on the latest conversational AI insights will make the brand ambassadors more efficient and effective in maintaining relationships.</li>
              <li><span class="benefit">Unlocked Customer Potential:</span> Based on the analysis, next-best action, upsell/cross-sell, and volume/velocity insights will help determine the long-tail value and proportionate investment strategies.</li>
              <li><span class="benefit">Compatibility and Sustainability:</span> A scalable and repeatable framework focused on the development of long-tail value through meaningful and sustained customer-brand relationships.</li>
              <li><span class="benefit">Roadmaps:</span> Behavioral and social insights will inform product development, customer journeys, experience evolution, and messaging across channels, audiences, and spending.</li>
              <li><span class="benefit">Perpetual Feedback:</span> Insights will further train brand-specific models, creating continuous feedback loops that listen, learn, assess, and iterate in real-time.</li>
              <li><span class="benefit">Upper-Funnel Integration:</span> Seamless integrations across all platforms ensure the proper conversation with the right person at the right moment of need (e.g., social media, email, live chat).</li>
              <li><span class="benefit">Privacy and Trust:</span> The platform is 100% opt-in, specifically designed and tailored to meet the needs of individual customers, with data privacy and consent our highest priority.</li>
            </ul>
          </div>
        </div>
      </div>
      <div>
        <img src="@/assets/img/industries/UmegoBenefits.png" />
      </div>
  </article>
</template>

<script>
export default {
  name: 'UmegoBenefits',
}
</script>
<style scoped lang="less">
@import (reference) "src/assets/css/style";
.benefit{
  color: #F6F5FA;
}
.container{
  margin: unset;
}
.art{
  display: flex;
  gap: @gap*0;
  .art-title{
    display: flex;
    flex-direction: column;
    gap: @gap*2;
    font-size: 28px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
    &__main{
      font-size: 54px;
      font-style: normal;
      font-weight: 700;
      line-height: 120%;
      color:#F3F2F7;
    }
  }
  img{
    height: calc(100vh - @headerHeight);
  }
  ul{
     padding-left: 15px;
   }
  .content{
    padding-top: 0;
  }
  @media(max-width: @screen-tablet) {
    gap: @gap*3;
    .art-title{
      font-size: 20px;
      &__main{
        font-size: 42px;
      }
    }
    //flex-direction: column;
    img{
      width: 100%;
      height: auto;
    }
  }
  @media(max-width: @screen-mobile) {
    gap: @gap*2;
    .art-title{
      font-size: 16px;
      &__main{
        font-size: 36px;
      }
    }
  }
}
</style>
