<template>
  <footer class="footer">
    <div class="container">
      <div class="footer__links">
        <div class="column" v-if="navItems.length">
          <h4 class="column__title">Umego.ai</h4>
          <nav class="footer__nav">
            <router-link
                class="list__item"
                v-for="(item, index) in navItems" :key="index" :to="item.path">
              {{ item.name }}
            </router-link>
          </nav>
        </div>
<!--        <div class="column">-->
<!--          <h4 class="column__title">about</h4>-->
<!--          <nav class="footer__nav">-->
<!--            <a href="">Story</a>-->
<!--            <a href="">Team</a>-->
<!--          </nav>-->
<!--        </div>-->
        <div class="column">
          <h4 class="column__title">Connect</h4>
          <nav class="footer__nav">
            <a href="mailto:connect@umego.ai">connect@umego.ai</a>
<!--            <a href="">LinkedIn</a>-->
          </nav>
        </div>
      </div>
      <div class="footer__content">
        <div class="footer__title">How can the magic of trust and empathy help you connect to your customers?</div>
        <a href="https://umego.typeform.com/to/tt5sSG0Y" target="_blank" class="footer__contact-link">
          <svg xmlns="http://www.w3.org/2000/svg" width="48" height="48" viewBox="0 0 48 48" fill="none">
            <g clip-path="url(#clip0_616_4314)">
              <path d="M31.5 0C30.675 0 30 0.675 30 1.5C30 2.325 30.675 3 31.5 3H42.8812L19.9406 25.9406C19.3594 26.5219 19.3594 27.4781 19.9406 28.0594C20.5219 28.6406 21.4781 28.6406 22.0594 28.0594L45 5.11875V16.5C45 17.325 45.675 18 46.5 18C47.325 18 48 17.325 48 16.5V1.5C48 0.675 47.325 0 46.5 0H31.5ZM6 3C2.69063 3 0 5.69063 0 9V42C0 45.3094 2.69063 48 6 48H39C42.3094 48 45 45.3094 45 42V28.5C45 27.675 44.325 27 43.5 27C42.675 27 42 27.675 42 28.5V42C42 43.6594 40.6594 45 39 45H6C4.34062 45 3 43.6594 3 42V9C3 7.34062 4.34062 6 6 6H19.5C20.325 6 21 5.325 21 4.5C21 3.675 20.325 3 19.5 3H6Z" fill="#86B2BD"/>
            </g>
            <defs>
              <clipPath id="clip0_616_4314">
                <rect width="48" height="48" fill="white"/>
              </clipPath>
            </defs>
          </svg>
          Get in touch with us.
        </a>
      </div>
      <div class="copyright">
        All rights reserved. Copyright ©{{currentYear}}
      </div>
    </div>
  </footer>
</template>

<script>
import {NAV_LIST} from "@/router";

export default {
  name: 'FooterComponent',
  props: {
    /*navItems: {
      type: Array
    }*/
  },
  data: function () {
    return {
      currentYear: false,
      navItems: NAV_LIST
    }
  },
  mounted() {
    this.currentYear = new Date().getFullYear();
  }
}
</script>

<style scoped lang="less">
@import (reference) "src/assets/css/style";

.footer {
  padding: @gap*2 0 @gap*0.66;
  color: @colorNeutralLight;
  background: linear-gradient(90deg, #281885 0%, #11093F 100%);

  .container {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-areas: 'links content'
    'copyright copyright';
    gap: @gap;
  }
  &__links {
    flex-grow: 1;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: @gap;
    font: 500 16px/150% @fontFamily;
    letter-spacing: -0.128px;
  }
  .column__title {
    position: relative;
    display: inline-block;
    margin-bottom: 12px;
    padding-bottom: 12px;
    font: 600 23px/125% @fontFamily;
    letter-spacing: 0.184px;

    &:after {
      content: '';
      position: absolute;
      left: 0;
      bottom: 0;
      width: 100%;
      height: 2px;
      background: linear-gradient(90deg, #281885 0%, #190E5A 50%, #0C053D 100%);
    }
  }
  &__nav {
    display: flex;
    flex-direction: column;
    gap: 16px;

    a {
      color: inherit;
      text-decoration: none;
    }
  }
  &__content {
    grid-area: content;
    display: flex;
    flex-direction: column;
    gap: @gap;
  }
  &__contact-link {
    grid-area: links;
    display: inline-flex;
    align-items: center;
    gap: @gap;
    text-decoration: none;
    color: inherit;
    font: 600 32px/125% @fontFamily;
  }
  .copyright {
    grid-area: copyright;
    width: 100%;
    text-align: center;
    margin-top: @gap*2;
    font: 16px/1rem @fontFamily;
    letter-spacing: 0.28px;
  }
  @media(max-width: @screen-mobile) {
    .container {
      grid-template-columns: 1fr;
      grid-template-areas:
        'content'
        'links'
        'copyright';
    }
  }
  @media(max-width: @screen-mobile_sm) {
    &__title {
      font: 300 26px/125% @fontFamily;
    }
    &__contact-link {
      font: 700 26px/125% @fontFamily;
      letter-spacing: 0.624px;
    }
    .copyright {
      margin-top: 0;
    }
    &__links {
      grid-template-columns: 1fr;
      padding-top: @gap;
    }
    .footer__nav {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr;
    }
    .column:first-child .footer__nav {
      grid-template-columns: 1fr 1fr;
    }
  }
}
</style>
