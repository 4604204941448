import { createRouter, createWebHistory } from 'vue-router'
import HomePage from "@/views/HomePage";
import RetailTransformPage from "@/views/RetailTransformPage";
import DemoPage from "@/views/DemoPage";
import PlatformPage from "@/views/PlatformPage";
import AboutPage from "@/views/AboutPage";
import IndustriesPage from "@/views/IndustriesPage.vue";

export const PATHS = {
  home: {
    root: '/',
  },
  retail: {
    root: '/retail-transformation',
    article1: '/retail-transformation?article=1',
    article2: '/retail-transformation?article=2',
    article3: '/retail-transformation?article=3',
    article4: '/retail-transformation?article=4',
    article5: '/retail-transformation?article=5',
  },
  platform: {
    root: '/platform',
  },
  industries: {
    root: '/industries',
  },
  about: {
    root: '/about',
  },
  demo: {
    root: '/demo',
  }
}

const routes = [
  {
    path: PATHS.home.root,
    name: 'home',
    component: HomePage
  },
  {
    path: PATHS.retail.root,
    name: 'retail',
    component: RetailTransformPage
    // props: { default: true, sidebar: false }
  },
  {
    path: PATHS.platform.root,
    name: 'platform',
    component: PlatformPage,
  },
  {
    path: PATHS.industries.root,
    name: 'industries',
    component: IndustriesPage,
  },
  {
    path: PATHS.about.root,
    name: 'about',
    component: AboutPage,

  },
  {
    path: PATHS.demo.root,
    name: 'demo',
    component: DemoPage
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    // component: () => import(/* webpackChunkName: "about" */ '../views/DemoPage.vue')
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  linkActiveClass: 'active',
    scrollBehavior(to, from,  savedPosition) {

      if (savedPosition) {
        return savedPosition;
      } else if (to.hash) {
        return {
          el: to.hash,
          behavior: 'smooth',
        }
      }
    return { top: 0 }
  }
  // linkExactActiveClass: 'active'
})

export default router


export const NAV_LIST= [
  {
    name: 'Retail transformation',
    path: PATHS.retail.root,
    // submenu: [{
    //   name: '0',
    //   path: PATHS.retail.root
    // }, {
    //   name: '1',
    //   path: PATHS.retail.root
    // }, {
    //   name: '2',
    //   path: PATHS.retail.root
    // }, {
    //   name: '3',
    //   path: PATHS.retail.root
    // }, {
    //   name: '4',
    //   path: PATHS.retail.root
    // }, {
    //   name: '5',
    //   path: PATHS.retail.root
    // }, {
    //   name: '6',
    //   path: PATHS.retail.root
    // }, {
    //   name: '7',
    //   path: PATHS.retail.root
    // }]
  },
  {
    name: 'Platform',
    path: PATHS.platform.root
  },
  {
    name: 'Industries',
    path: PATHS.industries.root
  },
  {
    name: 'About',
    path: PATHS.about.root
  },
  // {
  //   name: 'industries',
  //   path: PATHS.home.root
  // }, {
  //   name: 'Cases',
  //   path: PATHS.retail.root
  // }, {
  //   name: 'about',
  //   path: PATHS.retail.root
  // }, {
  //   name: 'Join',
  //   path: PATHS.retail.root
  // }
]
