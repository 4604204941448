<template>
  <article class="art platform container md">
    <div class="content ">
      <div class="content_title">UmeTap</div>
      <div class="content_text">
        <p>initiates a pairing during face-to-face introductions (think in-store clerks, direct sales teams, or
          influencer events). Tap allows both parties to share contact information quickly and efficiently while
          immediately reporting the connection to the analytics engine. Once connected, the ambassador and customer are
          linked through every brand channel, from commerce and POS to social and loyalty.</p>
        <p>Tap can be activated in a retail store, at an event, on the street, or anywhere a brand ambassador connects
          with a potential customer.</p>
      </div>
      <div class="content_tags">Meet. Tap. Share. Excite.</div>
    </div>
    <div class="screenshot">
      <img src="@/assets/img/platform/UmeTap.png" />
    </div>

  </article>
</template>

<script>
export default {
  name: 'UmeTap',
}
</script>
<style scoped lang="less">
@import (reference) "src/assets/css/style";

</style>
