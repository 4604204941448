<template>
  <article class="art">
    <div class="art-section standard container md">
      <div class="art-title">
        <h2 class="art-title__main">Generative AI - an evolutionary tale</h2>
        <h3 class="art-title__sub">Envisioning a future rooted in customer-centricity, trust, and authenticity shaping a
          world where businesses thrive on genuine connections.</h3>
      </div>
      <p>
        The introduction of ChatGPT was for many people their first glimpse into a new level of text generation, but the
        techniques used in ChatGPT, Google’s Bard, Apple’s Ferret, and other such models have been around for some time.
        Related techniques can generate not only text but also pictures, videos, and sound from a user’s written prompt.
        For text generation alone, dozens of open-source models with innumerable variations are available. They are
        astounding in their ability to produce the words that we want them to produce, but they can produce inaccurate
        text using confident wording (“hallucinations”).
      </p>
      <p>
        Also, the models know the patterns and sequences of words that humans use, so they produce text in those
        patterns and sequences, but they have no understanding of the text. It’s a bit like the age-old question of
        whether an infinite number of monkeys on typewriters would eventually produce the works of Shakespeare. Even if
        they did, they would not understand what they wrote.
      </p>
    </div>

    <div class="art-sep">
      <img src="@/assets/img/retail/lights-dark-blue.jpg" alt=""/>
    </div>

    <div class="art-section column container md">
      <div class="art-title">
        <h3 class="art-title__main">The data AI is collecting</h3>
      </div>
      <div class="content">
        <p>
          The data collected today for Generative AI to pump out content is conversion-centric and product-centric, not
          customer-centric. Why does this matter? Because Generative AI is scraping incomplete and fractional data to
          derive holistic insights and meaning. it is being assembled without a massive piece of the customer
          perspective. In the iceberg idiom, the data collected today is above the water line.
        </p>
        <p>
          Sure, brands know “who purchased what” and “when, where, and how it happened,” but they have no idea why
          because they know very little about the customer. The functional answers delivered through Generative AI are
          devoid of the emotional attributes driving the most powerful aspects of customer decision making. Think about
          it. What brands have access to customer interests, lifestyles, needs, desires, preferences, propensities,
          attributes, sentiments, satisfaction, or values? Who knows what is truly driving customer behavior or decision
          making? Which brands know the names of the children, favorite vacation destination, or song that makes them
          smile? How is Generative AI scraping that kind of detail if nobody is collecting it and posting it into the
          public sphere? Spoiler alert: Gen AI is not.
        </p>
      </div>
    </div>

    <div class="art-sep">
      <img src="@/assets/img/retail/eye.jpg" alt=""/>
    </div>


    <div class="art-section column container md">
      <div class="art-title">
        <h3 class="art-title__main">The massive gap</h3>
      </div>
      <div class="content">
        <p>
          We need a reality check. Hyperpersonal RFM remains a black hole. Next best action is a guess, sometimes
          educated, that makes most brands happy if it converts at 4%. Behavioral intelligence leads to more educated
          guesses, but they are only fractionally more valuable than the educated guesses without behavioral
          intelligence. Umego delivers unique characteristics and customer-centric insights that will make Gen AI more
          valuable across stakeholder groups. Our models are trained towards customer-centric insights, in conjunction
          with traditional omnichannel social, conversion and product-centric insights, to provide a holistic view of
          the customer. Our analytics engine becomes an integration hub that delivers holistic value to every
          customer-brand relationship.
        </p>
        <p>
          Before you get too wrapped up in the promise of Generative AI, remember that most only see the tip of the
          iceberg. As a CEO of AI at Salesforce recently stated in a Generative AI whitepaper, “Data is fuel for AI -
          without high-quality, trusted data, it becomes ‘garbage in, garbage out.’ AI pulling from data sources that
          are irrelevant, unrepresentative, or incomplete, can create bias, hallucinations and toxic outputs.” We agree.
          That’s why we get the data directly from the customer’s mouth.
        </p>
      </div>
    </div>
  </article>
</template>

<script>
export default {
  name: 'GenerativeAI ',
}
</script>
<style scoped lang="less">
@import (reference) "src/assets/css/style";

</style>
