<template>
  <div class="art-nav-wrapper grid-layout grid-layout__footer_crop">
    <ArticlesNav title='All articles' :articles="articles" class="grid-layout__sidebar"/>
    <div id="retail-transform" class="article-page grid-layout__content">
      <WhoWeAre id="WhoWeAre" />
      <div class="art-sep">
        <img src="@/assets/img/about/img.png" alt=""/>
      </div>
      <JoinUs id="JoinUs" />
    </div>
    <FooterComponent class="grid-layout__footer" />
  </div>
</template>

<script>
import ArticlesNav from "@/components/ArticlesNav.vue";
import FooterComponent from "@/components/Footer.vue";
import {store} from "@/store";
import WhoWeAre from "@/components/sections/About/WhoWeAre.vue";
import JoinUs from "@/components/sections/About/JoinUs.vue";

export default {
  name: 'AboutPage',
  components: {
    JoinUs,
    WhoWeAre,
    FooterComponent,
    ArticlesNav,
  },
  data () {
    return {
      articles: [{
        title: 'Who we are',
        id: 'WhoWeAre'
      }, {
        title: 'Join us',
        id: 'JoinUs'
      }]
    }
  },
  mounted() {
    this.$nextTick(() => {
      store.commit('loading', false);
    });
  },
  methods: {
  }
}
</script>

<style scoped lang="less">
@import (reference) "src/assets/css/style";

.wide-content {
  overflow: hidden;
}



</style>