<template>
  <article class="art platform">
    <div class="art-section standard container md">
      <div class="art-title">
        <h3 class="art-title__main big">UmeSales</h3>
      </div>
      <div class="flex-column">
        <div class="flex-row">
          <div class="screenshot">
            <img src="@/assets/img/platform/UmeSales.png"/>
          </div>
          <div>
            <p><span class="lead-in">UmeSales, powered by Wingman,</span> extends our AI-driven customer intelligence
              platform into next-generation B2B sales enablement, delivering training and coaching, digital asset
              management, performance tracking, news, AI-driven insights, livestream capabilities, and powerful
              analytics to improve performance and motivate sales organizations. </p>
            <p>Dynamic dashboards display deep B2B insights, including partnership status, transaction history, volume
              and velocity, churn, profitability, sales effectiveness, whitespace opportunity, propensity modeling, and
              real-time CLV projections. For twenty years, we’ve provided essential functionality to train internal
              sales teams, wholesalers, retailers, influencers, store clerks, and front-line employees to better:</p>
            <ul>
              <li>Align marketing and sales priorities</li>
              <li>Collaborate with cross-functional teams</li>
              <li>Organize product launches and program calendars</li>
              <li>Develop indispensable trusted partner relationships</li>
              <li>Ensure data-driven, evidence-based insights</li>
              <li>Deliver entrepreneurial intelligence to maximize value</li>
              <li>Infuse trust, empathy, care and the art of anticipation into the sales process</li>
            </ul>
            <p>UmeSales, powered by Wingman, has operationalized “just-in-time” knowledge to ensure that the full power
              of the brand is present in every sales call. With the addition of generative AI, livestream capabilities,
              and vectorized analytics, UmeSales is next generation sales enablement that perpetually nurtures and
              matures hyperpersonal relationships between brands and their most important B2B and B2C relationships.</p>
          </div>

        </div>

        <div class="companies">
          <img src="@/assets/img/platform/sun.png" width=""/>
          <img src="@/assets/img/platform/hilton.png" width=""/>
          <img src="@/assets/img/platform/capital.png" width=""/>
          <img src="@/assets/img/platform/cisco.png" width=""/>
          <img src="@/assets/img/platform/diageo.png" width=""/>
        </div>
      </div>
    </div>
  </article>
</template>

<script>
export default {
  name: 'UmeSales',
}
</script>
<style scoped lang="less">
@import (reference) "src/assets/css/style";

.companies {
  height: 56px;
  display: flex;
  gap: @gap;
  justify-content: space-around;

  img {
    height: 100%;
    align-self: center;
  }
}

.header {
  display: flex;
  gap: @gap*2;
  margin-bottom: @gap;

  img {
    object-fit: contain;
    align-self: center;
  }
}

.art-section {
  padding-bottom: initial;
}

.art-subtitle {
  color: #F2F0F7;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 125%; /* 30px */
  letter-spacing: -0.384px;
  margin-top: @gap;
}

.art {
  .lead-in {
    font-weight: 700;
    color: #F2F0F7;
  }

  ul {
    padding-left: @gap;
    margin-bottom: @gap;

    li {
      margin-bottom: initial;
    }
  }
}
</style>
