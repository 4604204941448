<template>
  <article class="art">
    <div class="art-section standard container md">
      <div class="art-title">
        <h2 class="art-title__main">Weaponizing Data</h2>
        <h3 class="art-title__sub">“Taken to the extreme this process creates an enduring digital profile and lets
          companies know you better than you may know yourself. Your profile is a bunch of algorithms that serve up
          increasingly extreme content, pounding our harmless preferences into harm.”</h3>
      </div>
      <p>
        In 2018, Apple Chief Executive Tim Cook said customer data was being “weaponized with military efficiency” by
        companies to increase profit and called for a federal privacy law in the United States. “Today, that trade has
        exploded into a data industrial complex. These scraps of data ... each one harmless enough on its own ... are
        carefully assembled, synthesized, traded, and sold,” Cook said. Mark Zuckerberg, speaking via video message,
        said Facebook users were aware of the trade-off between a free service and advertisements. “Instead of charging
        people, we charge advertisers to show ads. People consistently tell us that they want a free service and that if
        they are going to see ads to get it, they want those ads to be relevant,” he said. In defining his business
        model, Zuckerberg wholly owns that consumers are not the customers but the product.
      </p>
      <p>
        Why is data being weaponized with military efficiency? Advertisers (and politicians) know it’s faster and easier
        to master a predator-prey dynamic (manipulation, persuasion, preying upon, trolling, and scraping consumers)
        than to be customer-centric. “When scalability and repeatability trump compatibility and sustainability, the
        consumer always loses,” says Umego CEO Sean Dunn. “I’ve been in the agency business for 25 years. Our digital
        mechanisms for developing customer familiarity, empathy, trust, and care are based on automation. Email, SMS,
        retargeting, site personalization, and loyalty apps work at a distance. By definition, it forces brands to look
        for shortcuts, and the shortcuts are all predatory.”
      </p>
    </div>

    <div class="art-sep">
      <img src="@/assets/img/retail/office.jpg" alt=""/>
    </div>

    <div class="art-section column container md no-padding-bottom">
      <div class="art-title">
        <h3 class="art-title__main">Who benefits?</h3>
      </div>
      <div class="content">
        <p>
          Clearly, an enormous amount of money and careers depend on the current digital economy paradigm. Last year,
          global advertisers, digital marketing companies, publishers, social media platforms, influencers, etc., sold
          consumer access to brands for $1.568 trillion dollars. That’s a lot of money. As they prepare for a
          cookie-less digital economy, most of the recommendations continue to push maintaining reach, ensuring
          continuity in audience building and retargeting post-cookie, and collaborating securely across the ecosystem.
          It continues to focus on scalability and repeatability because that’s what the digital economy sells - massive
          amounts of guesses to see what sticks.
        </p>
        <p>
          New terms are emerging, like “privacy-by-design.” According to Forbes, “This involves considering data
          protection and privacy issues from the outset of a project or initiative rather than as an afterthought. By
          building privacy into your processes and systems, you can ensure that you collect and process personal data in
          a transparent and secure way.” Think about that for a second. Forbes is effectively saying, ““You need to
          balance your desire for personalization with consumers' desire for privacy.”
        </p>
      </div>
    </div>

    <div class="art-section column container md">
      <div class="art-title">
        <h3 class="art-title__main">A better path forward</h3>
      </div>
      <div class="content">
        <p>
          At Umego, we disagree. It’s not a balancing act. It’s a new relationship designed to provide hyperpersonal
          relationships between brands and their most important customers through a commitment that uses customer
          intelligence solely to serve the customer. “We believe deeply in long tail value gained only through trust,
          empathy, care, and the art of anticipation.”, says Dunn. “Our job is not to persuade, manipulate, scare, or
          troll. Our job is not to convince consumers to purchase things they don’t want or need. Our platform will
          raise the floor and push the ceiling for most clients because it is committed to using customer data
          intelligence to enhance and optimize customer-brand relationships. Full stop.”
        </p>
      </div>
    </div>

  </article>
</template>

<script>
export default {
  name: 'WeaponizingData',
}
</script>
<style scoped lang="less">
@import (reference) "src/assets/css/style";

</style>
