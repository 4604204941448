<template>
  <article class="art">
      <div class="column container md">
        <div class="art-title">
          <h3 class="art-title__main">Imagine a world where your customers feel seen</h3>
          <p>
            With Umego, they do. <br />
            <br />
            Not through algorithms, but through authentic human connection built right into your brand’s digital
            ecosystem.
          </p>
        </div>
      </div>
      <div>
        <img src="@/assets/img/platform/hero.png" />
      </div>
  </article>
</template>

<script>
export default {
  name: 'Hero',
}
</script>
<style scoped lang="less">
@import (reference) "src/assets/css/style";
.art{
  display: flex;
  gap: @gap*5;
  .art-title{
    display: flex;
    flex-direction: column;
    gap: @gap*2;
    font-size: 28px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
    &__main{
      font-size: 54px;
      font-style: normal;
      font-weight: 700;
      line-height: 120%;
      color:#F3F2F7;
    }
  }
  img{
    height: calc(100vh - @headerHeight);
  }
  @media(max-width: @screen-tablet) {
    gap: @gap*3;
    .art-title{
      font-size: 20px;
      &__main{
        font-size: 42px;
      }
    }
    //flex-direction: column;
    img{
      width: 100%;
      height: auto;
    }
  }
  @media(max-width: @screen-mobile) {
    gap: @gap*2;
    .art-title{
      font-size: 16px;
      &__main{
        font-size: 36px;
      }
    }
  }
}
</style>
