<template>
  <article class="art platform">
    <div class="art-section standard container md">
      <div class="column">
        <div>Customers want a person who cares about them.</div>
        <div>Brands want retention and loyalty.</div>
        <div class="color">Umego delivers both.</div>
      </div>
    </div>
  </article>
</template>

<script>
export default {
  name: 'Section11'
}
</script>

<style scoped lang="less">
@import (reference) "src/assets/css/style";
.column{
  font: 700 34px/125% @fontFamily;
}
.color{
  width: fit-content;
  background: linear-gradient(90deg, #AFCBD3 0%, #9285E3 50.03%, #BD3C7D 100%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}


</style>
