<template>
  <Hero class="article-page" />
  <div class="art-nav-wrapper grid-layout grid-layout__footer_crop">
    <ArticlesNav title='Platform' :articles="articles" class="grid-layout__sidebar"/>
    <div id="platform" class="article-page grid-layout__content">
      <CustomerIntelligencePlatform id="CustomerIntelligencePlatform" />
      <UmeTap id="UmeTap" />
      <UmeChat id="UmeChat" />
      <UmeShop id="UmeShop" />
      <UmeShow id="UmeShow" />
      <SeamlessIntegration id="SeamlessIntegration" />

      <AIpoweredIntelligenceEngine id="AIpoweredIntelligenceEngine"/>
      <TheHub id="TheHub" />
      <UmeSales id="UmeSales" />
    </div>
    <FooterComponent class="grid-layout__footer" />
  </div>
</template>

<script>
import {store} from "@/store";

import ArticlesNav from "@/components/ArticlesNav.vue";
import Hero from "@/components/sections/Platform/Hero.vue";
import UmeChat from "@/components/sections/Platform/UmeChat.vue";
import UmeShop from "@/components/sections/Platform/UmeShop.vue";
import UmeTap from "@/components/sections/Platform/UmeTap.vue";
import UmeShow from "@/components/sections/Platform/UmeShow.vue";
import SeamlessIntegration from "@/components/sections/Platform/SeamlessIntegration.vue";
import FooterComponent from "@/components/Footer.vue";
import CustomerIntelligencePlatform from "@/components/sections/Platform/CustomerIntelligencePlatform.vue";
import AIpoweredIntelligenceEngine from "@/components/sections/Platform/AIpoweredIntelligenceEngine.vue";
import TheHub from '@/components/sections/Platform/TheHub.vue'
import UmeSales from '@/components/sections/Platform/UmeSales.vue'

export default {
  name: 'PlatformPage',
  components: {
    UmeSales,
    TheHub,
    AIpoweredIntelligenceEngine,
    CustomerIntelligencePlatform,
    FooterComponent,
    ArticlesNav,
    Hero,
    UmeChat,
    UmeShop,
    UmeTap,
    UmeShow,
    SeamlessIntegration,
  },
  data () {
    return {
      articles: [
        {
          title: 'Customer intelligence platform',
          id:    'CustomerIntelligencePlatform',
          submenu:[
            {
              title: 'UmeTap',
              id:    'UmeTap'
            },
            {
              title: 'UmeChat',
              id:    'UmeChat'
            }, {
              title: 'UmeShop LIVE',
              id:    'UmeShop'
            }, {
              title: 'UmeShow LIVE',
              id:    'UmeShow'
            }, {
              title: 'Seamless Integration',
              id:    'SeamlessIntegration'
            },
          ]
        },
        {
          title: 'AI-powered Intelligence Engine',
          id:    'AIpoweredIntelligenceEngine'
        },
        {
          title: 'The Hub',
          id:    'TheHub'
        },
        {
          title: 'UmeSales',
          id:    'UmeSales'
        },
      ]
    }
  },
  mounted() {
    this.$nextTick(() => {
      store.commit('loading', false);
    });
  },
  methods: {

  }
}
</script>

<style scoped lang="less">
@import (reference) "src/assets/css/style";
#platform{
  display: flex;
  flex-direction: column;
  gap: @gap*2.5;
}
</style>
