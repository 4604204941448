<template>
  <section class="section9">
    <div class="container md">
      <div class="wrap">

        <div class="content">
          <div class="flex-column">
          <p>
            Over twenty years, you’ve learned who purchased what, and you know when, where, and how it happened…
          </p>
          <p>
            ...but you still don’t know why.
          </p>
          <p>
            Hyperpersonal customer engagement, powered by immersive experiences with AI-driven intelligence, will tell
            you why… and will unleash explosive growth across your business.
          </p>
          </div>
        </div>

        <div class="media">
          <div class="device">
            <img src="@/assets/img/home/phone.png" alt="">
          </div>
          <!--          <div class="phone">
                      <img src="@/assets/img/home/device.png" alt="">
                    </div>-->
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: 'Section9'
}
</script>

<style scoped lang="less">
@import (reference) "src/assets/css/style";

.section9 {
  position: relative;
  width: 100%;
  //overflow: hidden;
  padding: @gap*1 0 @gap*4;
  //background: #0d063e url('@/assets/img/home/section-2.png') center center no-repeat;
  //background-size: cover;
  //background-blend-mode: overlay;
  font: 300 24px/125% @fontFamily;
  color: #F6F5FA;

  /*  &:before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
    }*/

  @media (max-width: @screen-tablet) {
    padding: @gap*3 0;
    font: 500 18px/150% @fontFamily;
  }
  @media (max-width: @screen-mobile_sm) {
    padding: @gap*2 0;
    font: 500 16px/150% @fontFamily;
  }
}

.wrap {
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: @gap*2;
  padding: @gap*2.5 0; // @gap*6 @gap*2.5 0;

  &:before {
    content: '';
    position: absolute;
    right: @gap*(-2.8);
    top: 0;
    height: 100%;
    width: 120vw;
    //background: linear-gradient(90deg, #3F2AB0 0%, #281885 50%, #0C053D 100%);
    background: linear-gradient(90deg, #0C053D 0%, #251C8A 100%);
  }
}

.content {
  position: relative;
  width: min(860px, 100%);
}

.media {
  flex-grow: 2;
  @media (max-width: @screen-mobile_sm) {

  }
}

.device {
  position: relative;
   width: 205px;
  max-width: 100%;

  img {
    width: 184px;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    margin: auto;
  }

  @media (max-width: @screen-mobile_sm) {
    max-width: 50%;
  }
}


</style>
