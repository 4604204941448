import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import {store} from "./store";
import AOS from 'aos';
import 'aos/dist/aos.css';
import 'animate.css';
/*import { gsap } from 'gsap'*/

const app = createApp(App);

const animationDuration = 300;

app.config.globalProperties.time = animationDuration
app.config.globalProperties.getTime = (priority) => (animationDuration * priority)

app.use(router).use(store).mount('#app');
// .use(gsap)
// createApp(App).use(router).use(store).mount('#app')

AOS.init({
  animatedClassName: 'in',
  once: true,
  offset: 200,
  duration: animationDuration
});

// Vue.prototype.$time = 'test';