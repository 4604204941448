<template>
  <article class="art platform">
    <div class="art-section standard container md">
      <h3 class="art-title__main">The Umego Philosophy</h3>
      <div class="flex-row">

        <div class="accordion col">
          <div @click="navClick(1)" :class="{'in': currentItem==1}" class="tab">
            <p>1. Relationships require contextually relevant, human connection</p>
            <ul>
              <li>Offer always-on, just-in-time answers, support, & brand love</li>
              <li>What customers need, when they need it, wherever they are</li>
              <li>Delivered by someone they know, trust, and enjoy</li>
              <li>{Immediate} {Informed} {Valued} {AI powered}</li>
            </ul>
          </div>
          <div @click="navClick(2)" :class="{'in': currentItem==2}" class="tab">
            <p>2. Empowered brand ambassadors will nurture brand loyalty</p>
            <ul>
              <li>Relationships steeped in trust, empathy, care and anticipation</li>
              <li>make customers feel understood, respected, and loved</li>
              <li>and employees feel empowered, invested, and rewarded</li>
              <li>{Authentic} {Familiar} {Available} {Warm}</li>
            </ul>
          </div>
          <div @click="navClick(3)" :class="{'in': currentItem==3}" class="tab">
            <p>3. AI power will unlock “why consumers do what they do”</p>
            <ul>
              <li>Fusing human intelligence with powerful AI-driven insights</li>
              <li>produces differentiated data new to the marketplace</li>
              <li>and offers the gift of anticipation</li>
              <li>{Predict} {Listen} {Learn} {Appreciate}</li>
            </ul>
          </div>
          <div @click="navClick(4)" :class="{'in': currentItem==4}" class="tab">
            <p>4. Next-generation CLV will be customer-centric</p>
            <ul>
              <li>Intelligence from millions of interactions will optimize</li>
              <li>how brands acquire, nurture, and retain customers</li>
              <li>and it will transform retail forever</li>
              <li>{Evolve} {Expand} {Grow} {Breathe}</li>
            </ul>
          </div>
        </div>
        <div class="img">
          <!--          <img src="@/assets/img/home/umegoPhilosophy.png"/>-->
        </div>

      </div>
    </div>
  </article>
</template>

<script>
export default {
  name:    'Section12',
  data:    function () {
    return {
      currentItem: false,
    }
  },
  methods: {
    navClick: function (item) {
      console.log('CLICK', item)
      this.currentItem = (this.currentItem == item) ? false : item
    },
  }
}
</script>

<style scoped lang="less">
@import (reference) "src/assets/css/style";

.flex-row {
  .col{
    flex-basis: 60%;
  }
}

.accordion {

  .tab {
    border-bottom: 1px solid rgba(248, 247, 252, 0.60);
    padding: (@gap/2);

    p {
      position: relative;
      font: 400 18px/150% @fontFamily;
      color: #F6F5FA;
      &:after {
        content: '';
        position: absolute;
        right: 0;
        width: 24px;
        height: 24px;
        background: url("~@/assets/img/plus-large.svg") no-repeat;
        background-size: 100% auto;
        .animate(@duration*1s);
      }
    }

    ul {
      margin-top: 8px;
      padding-left: 24px;
      max-height: 0;
      overflow: hidden;
      font: 400 16px/150% @fontFamily;
      color: #F3F1F7;
      .animate(@duration*1s);

      li {
        list-style: none;
        margin-bottom: 0;
      }
    }
  }

  .in {
    p:after{
      transform: rotate(45deg);
    }
    ul {
      max-height: 96px;
      @media(max-width: @screen-mobile) {
        max-height: 500px;
      }
    }
  }

}

.img {
  flex: 1;
  background: url('~@/assets/img/home/umegoPhilosophy.png') center/contain no-repeat;
}
</style>
