<template>
  <article class="art">
    <div class="container md">
      <div class="art-section standard">
        <div class="art-title">
          <h1 class="art-title__main big">Human Connection for the Digital Age</h1>
          <h3 class="art-title__sub">
            “Your business is not in a relationship with a billion people. Your business is in a billion relationships.
            <br/>
            <br/>
            How you nurture and support every relationship will determine your success.”
          </h3>
        </div>
        <p>
          For more than a decade, technology’s response has been to collect more data via CRM, CDP, data lakes, ML, and
          AI. While data collection, organization, dissemination, and activation have increased our understanding of
          customer behavior, relationships are more than data.
        </p>
        <p>
          Nurturing relationships is where technology companies, advertisers, and social platforms have dropped the
          ball. First, almost all of the data analyzed within digital channels is product-centric and
          conversion-centric. Second, the primary goal with that data is to squeeze every drop of immediate (short-tail)
          product-centric and conversion-centric value for the brand. Even loyalty apps have become little more than
          offer machines. Third, brands are told that the data collected represents a holistic view of the customer.
        </p>
        <p>
          It does not.
        </p>
      </div>
    </div>

    <div class="clear pt"></div>

    <div class="art-sep">
      <img src="@/assets/img/retail/pink-matrix-reverse.jpg" alt=""/>
    </div>

    <div class="art-section column container md">
      <div class="art-title">
        <h3 class="art-title__main">Differentiated data</h3>
      </div>
      <div class="content">
        <p>
          We’ve created proprietary AI-powered data intelligence models that use social and behavioral sciences to
          collect the kinds of data-driven insights missing in the market today. These insights are accessed by brand
          ambassadors while delivering concierge, hospitality, and personal shopper services within our innovative
          customer connection platform. Umego empowers people to listen, learn, inquire, suggest, recommend, engage, and
          activate on a human level. We collect intelligence to better serve each customer while simultaneously building
          better aggregate models.
        </p>
        <p>
          As the models learn, they deliver new insights to the brand ambassador. The ambassador uses those insights to
          offer customers more immersive experiences. Every action is collected and fed back into the models, improving
          intelligence and delivering more mature insights to inform future customer interaction. Our combination of
          old-world values working with new-world capabilities creates a virtuous cycle that becomes more efficient and
          effective over time.
        </p>
      </div>
    </div>

    <div class="art-sep art-sep__phone art-sep__phone_right">
      <div class="container md">
        <img src="@/assets/img/retail/device_1.png" class="art-sep__device" alt=""/>
      </div>
      <img src="@/assets/img/retail/night-city.jpg" alt=""/>
    </div>

    <div class="art-section column container md">
      <div class="art-title">
        <h3 class="art-title__main">Paradigm shift</h3>
      </div>
      <div class="content">
        <p>
          Additional layers of human understanding shift the paradigm. Sales teams become trusted advisors.
          Product-centric selling gives way to customer-centric activation. 1st party data become 1st person
          relationships. Anticipation replaces manipulation. Authenticity replaces noise. Most importantly, through
          human engagement, we answer the elusive question of “why your customers do what they do.”
        </p>
        <p>
          Envision the value of zero-party human intelligence across your business, influencing decision-making related
          to new customer acquisition, product roadmaps, launch strategies, merchandising, and customer satisfaction.
          Umego will forever change retail, offering brands unlimited opportunities to acquire, nurture, and retain
          advocate-level customer-brand relationships. It’s the future of retail.
        </p>
      </div>
    </div>
  </article>
</template>

<script>
export default {
  name: 'HumanConnection',
}
</script>
<style scoped lang="less">
@import (reference) "src/assets/css/style";

.art-section {
  overflow: hidden;
}


.wide-bg-1 {
  position: relative;
  height: 648px;
  margin-bottom: 0.5rem;
  background: url('@/assets/img/retail/road.jpg') center top no-repeat;
  background-size: cover;

  .container {
    position: relative;
    height: 100%;
  }

  .container:before {
    content: '';
    position: absolute;
    left: 3rem;
    bottom: -25px;
    width: 294px;
    height: 606px;
    background: url('@/assets/img/retail/screen.png') bottom center no-repeat;
  }

  @media (max-width: @screen-tablet) {
    height: auto;
    padding-top: 1rem;
    padding-bottom: 1rem;

    .container:before {
      position: static;
      display: block;
      margin: 0 auto;
    }
  }
}

</style>
