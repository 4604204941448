<template>
  <article class="section6 art platform">
    <div class="art-section standard container md">
      <div class="flex-row">
        <div class="flex-column">
          <h3>
            Your most important relationships are not algorithms. They’re people.
          </h3>
          <h3>
            Let’s get to know them.
          </h3>
        </div>
        <div class="flex-column">
          <p>
            Umego empowers brand ambassadors (direct sales teams, clerks, concierge, influencers, advocates, partners,
            etc.) to build recurring relationships with the brand’s most important customers.
          </p>
          <p>
            Contextually relevant, AI-driven hyperpersonal engagement steeped in trust, care, empathy, and anticipation.
            The kind of engagement that makes your customers feel understood, appreciated, respected, and loved. Umego
            is where old world values meet new world capabilities.
          </p>
        </div>
      </div>
    </div>
  </article>
</template>

<script>
export default {
  name: 'Section6',
}
</script>

<style scoped lang="less">
@import (reference) "src/assets/css/style";

.section6 {
  //padding: @gap*4 0;
  //font: 29px/150% @fontFamily;
  //color: @colorNeutralLight;

  h3 {
    font: 400 28px/125% @fontFamily;
    color: @colorNeutralLight;
    //margin-bottom: @gap*2;
  }
  .flex-column{
    justify-content: center;
    flex-basis: 50%;
  }

  @media (max-width: @screen-tablet) {
    h3 {
      font: 700 29px/120% @fontFamily;
      font: 400 22px/125% @fontFamily;
    }
  }
  @media (max-width: @screen-mobile_sm) {
    h3 {
      font: 700 24px/120% @fontFamily;
    }
  }
}

</style>
