<template>
  <article class="art">
    <div class="art-section standard container md">
      <div class="art-title">
        <h3 class="art-title__main big">Customer Intelligence Platform</h3>
      </div>
      <div class="content">
        <p>
          For companies of five people or 5K stores, Umego makes relationships based on zero-party human intelligence
          possible. We train and coach all stakeholders. We build business models that lean into concierge, hospitality,
          direct selling, and personal shopper intelligence. We arm every ambassador with live dashboards,
          next-generation tools, and deep psychographic insights focusing on customer-centric values. We add automation
          to simulated human experiences, enabling limitless scale. We build more brilliant data models that predict and
          anticipate needs. We reward success. Umego puts a human wrapper around clienteling, live streaming, generative
          AI, real-time analytics, and customer insights to create human connections for the digital age.
        </p>
        <p class="art-subtitle">Our multi-faceted platform includes the following features:</p>
      </div>
    </div>

  </article>
</template>

<script>
export default {
  name: 'CustomerIntelligencePlatform',
}
</script>
<style scoped lang="less">
@import (reference) "src/assets/css/style";

.art-section {
  padding-bottom: initial;
}

.art-subtitle {
  color: #F2F0F7;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 125%; /* 30px */
  letter-spacing: -0.384px;
  margin-top: @gap*2;
}
</style>
